import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { UserActivity } from '../models/userActivity';
import { Supplier } from '../models/supplier';
import { PurchaseOrder, PurchaseOrderFile } from '../models/purchaseOrder';
import { StateService } from './state.service';
import { HttpResponse } from '@angular/common/http';
import { Category, Product, Brand } from '../models/product';
import { PurchaseOrderProduct } from '../models/purchaseOrder';
import { Country } from '../models/country';
import { Location } from '../models/location';
import { InventoryError } from '../models/inventory_error';
import { StockTake } from '../models/stock-take';
import { SupplierFile } from '../models/supplier-file';
import { ProductFile } from '../models/product-file';
import { BrandFile } from '../models/brand-file';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { ProductPriceChange } from '../models/product-price-change';
@Injectable({
  providedIn: 'root'
})
export class WebService {

  private selectedIds = new Set<number>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private state: StateService
  ) {
    this.setUserRoles();
  }

  get headers(): HttpHeaders {
    return this.getHttpHeader();
  }

  getHttpHeader() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return headers;
  }

  addId(id: number) {
    this.selectedIds.add(id);
  }

  removeId(id: number) {
    this.selectedIds.delete(id);
  }

  getSelectedIds(): Set<number> {
    return this.selectedIds;
  }

  clearSelectedIds() {
    this.selectedIds.clear();
  }

  getSupplierUploadUrl() {
    return environment.api_endpoint + `supplier/upload`;
  }

  getProductUploadUrl() {
    return environment.api_endpoint + `products/upload`;
  }

  getBrandUploadUrl() {
    return environment.api_endpoint + `brands/upload`;
  }

  getPurchaseOrderUploadUrl() {
    return environment.api_endpoint + `purchase-orders/upload`;
  }

  //About Login Logic: 關於登錄邏輯的方法! 請放在這裏。
  async login(username: string, password: string) {
    const api_url = environment.api_endpoint + 'auth/login';
    const body = { username, password };

    try {
      const res = await this.http.post(api_url, body, {
        headers: this.headers,
        observe: 'response',
        responseType: 'json',
        withCredentials: true
      }).toPromise();

      if (res && (res.status === 200 || res.status === 201) && res.body) {
        const userInfo = res.body;
        this.state.saveLogin(userInfo);
        console.log('User info stored in localStorage:', userInfo);
      }
      return res;
    } catch (error) {
      console.error('Error:', error);
      return error;
    }
  }

  async checkAutoLoginSession() {
    const user = localStorage.getItem('user');
    if (user) {
      const userInfo = JSON.parse(user);
      const username = userInfo.username;

      try {
        const api_url = environment.api_endpoint + 'auth/check-login-record';
        const res: any = await this.http.post(api_url, { username }, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true
        }).toPromise();

        if (res && (res.status === 200 || res.status === 201) && res.body) {
          console.log('User has valid login record, redirecting...');
          this.state.saveLogin(userInfo);
          this.router.navigate(['/products']);
        }
      } catch (error) {
        console.error('Error checking login record:', error);
      }
    }
  }


  //About product service methods：關於產品服務的邏輯方法! 請放在這裏。
  async getAllProducts(): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + 'products';
    var params = new HttpParams().appendAll({});
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true })
      .toPromise()
      .then((res?: HttpResponse<Object>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }

  async addProduct(product: any): Promise<any> {
    const url = `${environment.api_endpoint}products`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.post(url, product, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to add product', error);
      throw error;
    }
  }

  async getProductFiles(productId?: number) {
    const response = await fetch(`${environment.api_endpoint}product-file/product/${productId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  }

  async getBrandFiles(brandId?: number) {
    const response = await fetch(`${environment.api_endpoint}brand-file/brand/${brandId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  }

  async getPurchaseOrderFiles(purchaseOrderId?: number) {
    const response = await fetch(`${environment.api_endpoint}purchase-order-file/purchase-order/${purchaseOrderId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  }

  async getProductSpecifications(productId?: number) {
    const response = await fetch(`${environment.api_endpoint}productspecifications/product/${productId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  }

  async editProduct(id: number, product: any): Promise<any> {
    const url = `${environment.api_endpoint}products/${id}/`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.post(url, product, { headers }).toPromise();
      console.log('Product updated response:', response);
      return response;
    } catch (error) {
      console.error('Failed to edit product', error);
      throw error;
    }
  }

  async productNameExists(name: string): Promise<any> {
    const url = `${environment.api_endpoint}products/is_exist_product_name/${name}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.get(url, {headers: headers}).toPromise();
      console.log('Product name exists:', response);
      return response
    } catch (error) {
      console.error('Failed to get product name', error);
      throw error;
    }
  }

  async productBarcodeExists(barcode: string): Promise<any> {
    const url = `${environment.api_endpoint}products/is_exist_product_barcode/${name}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.get(url, {headers: headers}).toPromise();
      console.log('Barcode name exists:', response);
      return response
    } catch (error) {
      console.error('Failed to get barcode name', error);
      throw error;
    }
  }

  // async editProduct(id: number, product: any) {
  //   const url = `${environment.api_endpoint}products/${id}/`;
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   try {
  //     const response = await fetch(url, {
  //       method: 'Post',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(product),
  //     });
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const result = await response.json();
  //     console.log('Product updated:', result);
  //   } catch (error) {
  //     console.error('Error updating product:', error);
  //   }
  // };


  async deleteProduct(id: number) {

  }

  async getImageUrl(productId: string, imageType: string) {
    const api_url = environment.api_endpoint + `products/${productId}/image?key=${imageType}`;
    try {
      const res = await this.http.get(api_url, { headers: this.headers, withCredentials: true }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  // getBackImage(productId: string) {
  //   return `/api/products/${productId}/back_image`;
  // }

  // getBarcodeImage(productId: string) {
  //   return `/api/products/${productId}/barcode_image`;
  // }
  // getInnerPackagingImage(productId: string) {
  //   return `/api/products/${productId}/inner_packaging_image`;
  // }
  // getCompareAtPriceImage(productId: string) {
  //   return `/api/products/${productId}/compare_at_price_image`;
  // }




  //About user service methods：關於用戶服務的邏輯方法! 請放在這裏。
  async addUser(user: User): Promise<any> {
    const api_url = environment.api_endpoint + 'users';
    try {
      const res = await this.http.post(api_url, user, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async updateUser(userId: number, updateId: number, updateUserDto: any): Promise<any> {
    const api_url = `${environment.api_endpoint}users/${userId}?user_id=${updateId}`;
    try {
      const res = await this.http.put(api_url, updateUserDto, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(userId: number, updateId: number, newPassword: string): Promise<any> {
    const api_url = environment.api_endpoint + `users/${userId}/reset-password?user_id=${updateId}`;
    try {
      const res = await this.http.patch(api_url, { newPassword }, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async disableUser(user: User): Promise<any> {
    const api_url = environment.api_endpoint + `users/disable/${user.id}`;
    try {
      const res = await this.http.patch(api_url, {}, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getAllUsers(): Promise<any> {
    const api_url = environment.api_endpoint + 'users';
    var params = new HttpParams().appendAll({

    });
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)
  }

  async setUserRoles() {
    this.getAllRoles().then((res: any) => {
      if (res.status >= 200 && res.status < 299) {
        const roles = res.body;
        this.state.setRoles(roles);
      }
    });
  }

  async getAllRoles(): Promise<any> {
    const api_url = environment.api_endpoint + 'roles';
    var params = new HttpParams().appendAll({

    });
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)
  }

  async getOneUser(user: User): Promise<any> {
    const api_url = environment.api_endpoint + `users/${user.id}`;
    try {
      const res = await this.http.get(api_url, { headers: this.headers, withCredentials: true }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async addUserActivity(user: UserActivity): Promise<any> {
    const api_url = environment.api_endpoint + 'user-activity';
    try {
      const res = await this.http.post(api_url, user, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  //About supplier service methods：關於供應商服務的邏輯方法! 請放在這裏。
  async getAllSuppliers(): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + 'supplier';
    var params = new HttpParams().appendAll({});
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true })
      .toPromise()
      .then((res?: HttpResponse<Object>) => {
        return res;
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }

  async addSupplier(supplier: Supplier): Promise<any> {
    const api_url = environment.api_endpoint + 'supplier';
    try {
      const res = await this.http.post(api_url, supplier, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getSupplierDetail(id: number): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + `supplier/${id}`;
    var params = new HttpParams().appendAll({});
    return this.http.get<Supplier>(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true })
      .toPromise()
      .then((res?: HttpResponse<Supplier>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }

  async updateSupplier(id: number, user_id: number, supplier: Supplier): Promise<any> {
    const api_url = environment.api_endpoint + `supplier/${id}?user_id=${user_id}`;
    try {
      const res = await this.http.put(api_url, supplier, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getSupplierFileSignedUrl(storage_filename: string): Promise<string> {
    const api_url = environment.api_endpoint + 'supplier/file/' + storage_filename;
    try {
      const res = await this.http.get(api_url, { headers: this.headers }).toPromise();
      return res?.['url'] as string;
    } catch (error) {
      console.error('Error fetching supplier file signed URL:', error);
      throw error;
    }
  }

  async supplierNameExistsZh(name: string): Promise<any> {
    const url = `${environment.api_endpoint}supplier/is_exist_supplier_zh/${name}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.get(url, {headers: headers}).toPromise();
      return response
    } catch (error) {
      console.error('Failed to get supplier_zh name', error);
      throw error;
    }
  }

  async supplierNameExistsEn(name: string): Promise<any> {
    const url = `${environment.api_endpoint}supplier/is_exist_supplier_en/${name}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.get(url, {headers: headers}).toPromise();
      return response
    } catch (error) {
      console.error('Failed to get supplier_en name', error);
      throw error;
    }
  }

  async supplierCompanyNameExistsZh(name: string): Promise<any> {
    const url = `${environment.api_endpoint}supplier/is_exist_supplier_company_zh/${name}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.get(url, {headers: headers}).toPromise();
      return response
    } catch (error) {
      console.error('Failed to get supplier_company_zh name', error);
      throw error;
    }
  }

  async supplierCompanyNameExistsEn(name: string): Promise<any> {
    const url = `${environment.api_endpoint}supplier/is_exist_supplier_company_en/${name}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.get(url, {headers: headers}).toPromise();
      return response
    } catch (error) {
      console.error('Failed to get supplier_company_en name', error);
      throw error;
    }
  }

  async getProductFileSignedUrl(storage_filename: string): Promise<string> {
    const api_url = environment.api_endpoint + 'products/file/' + storage_filename;
    try {
      const res = await this.http.get(api_url, { headers: this.headers }).toPromise();
      return res?.['url'] as string;
    } catch (error) {
      console.error('Error fetching product file signed URL:', error);
      throw error;
    }
  }

  async getBrandFileSignedUrl(storage_filename: string): Promise<string> {
    const api_url = environment.api_endpoint + 'brands/file/' + storage_filename;
    try {
      const res = await this.http.get(api_url, { headers: this.headers }).toPromise();
      return res?.['url'] as string;
    } catch (error) {
      console.error('Error fetching brand file signed URL:', error);
      throw error;
    }
  }

  async getPurchaseOrderFileSignedUrl(storage_filename: string): Promise<string> {
    const api_url = environment.api_endpoint + 'purchase-orders/file/' + storage_filename;
    try {
      const res = await this.http.get(api_url, { headers: this.headers }).toPromise();
      return res?.['url'] as string;
    } catch (error) {
      console.error('Error fetching purchase order file signed URL:', error);
      throw error;
    }
  }

  async addSupplierFile(supplierFile: Partial<SupplierFile>): Promise<any> {
    const api_url = environment.api_endpoint + 'supplier-file';
    try {
      const res = await this.http.post(api_url, supplierFile, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async addProductFile(id: number, productFile: Partial<ProductFile>): Promise<any> {
    const api_url = environment.api_endpoint + `product-file?user_id=${id}`;
    try {
      const res = await this.http.post(api_url, productFile, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async addBrandFile(brandFile: Partial<BrandFile>): Promise<any> {
    console.log(brandFile);
    const api_url = environment.api_endpoint + 'brand-file';
    try {
      const res = await this.http.post(api_url, brandFile, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async addPurchaseOrderFile(purchaseOrderFile: Partial<PurchaseOrderFile>): Promise<any> {
    const api_url = environment.api_endpoint + 'purchase-order-file';
    try {
      const res = await this.http.post(api_url, purchaseOrderFile, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async deleteSupplierFile(id: number) {
    const api_url = environment.api_endpoint + `supplier-file/${id}`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async deleteBrandFile(id: number) {
    const api_url = environment.api_endpoint + `brand-file/${id}`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async deleteProductFile(user_id: number, id: number) {
    const api_url = environment.api_endpoint + `product-file/${id}?user_id=${user_id}`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async deletePurchaseOrderFile(id: number) {
    const api_url = environment.api_endpoint + `purchase-order-file/${id}`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  //About purchase order service methods：關於採購單服務的邏輯方法! 請放在這裏。
  async getAllPurchaseOrders(): Promise<any> {
    const api_url = environment.api_endpoint + 'purchase-orders';
    var params = new HttpParams().appendAll({

    });
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)

  }

  async getOnePurchaseOrder(id: number): Promise<any> {
    const api_url = environment.api_endpoint + `purchaseOrders/${id}`;
    try {
      const res = await this.http.get(api_url, { headers: this.headers, withCredentials: true }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async bulkUpdatePurchaseOrders(updatedPurchaseOrders: any[]): Promise<any> {
    const api_url = environment.api_endpoint + 'purchase-orders/bulk-update';
    return this.http.put(api_url, updatedPurchaseOrders, { headers: this.headers, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res);
        return res;
      }).catch(error => error);
  }

  async addPurchaseOrder(purchaseOrders: any): Promise<any> {
    const api_url = environment.api_endpoint + 'purchase-orders';
    try {
      const res = await this.http.post(api_url, purchaseOrders, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }


  async getPurchaseOrderDetail(id: number): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + `purchase-orders/${id}`;
    var params = new HttpParams().appendAll({});
    return this.http.get<PurchaseOrder>(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true })
      .toPromise()
      .then((res?: HttpResponse<PurchaseOrder>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }

  async getPurchaseOrderProductsDetail(id: number): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + `purchase-order-products/order/${id}`;
    var params = new HttpParams().appendAll({});
    return this.http.get<PurchaseOrder>(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true })
      .toPromise()
      .then((res?: HttpResponse<PurchaseOrder>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }

  async getPurchaseOrderFilesDetail(id: number): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + `purchase-order-files/order/${id}`;
    var params = new HttpParams().appendAll({});
    return this.http.get<PurchaseOrder>(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true })
      .toPromise()
      .then((res?: HttpResponse<PurchaseOrder>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }

  async updatePurchaseOrders(id: number, user_id: number, purchaseOrder: any): Promise<any> {
    const api_url = `${environment.api_endpoint}purchase-orders/${id}?user_id=${user_id}`;
    try {
      const res = await this.http.put(api_url, purchaseOrder, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }



  async getProductList(): Promise<any> {
    const api_url = environment.api_endpoint + 'products';
    var params = new HttpParams().appendAll({

    });
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)
  }

  async getOneProduct(id: number): Promise<any> {
    const api_url = environment.api_endpoint + `products/${id}`;
    var params = new HttpParams().appendAll({

    });
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)
  }

  async getAllCategories(): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + 'category';
    var params = new HttpParams().appendAll({});
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true })
      .toPromise()
      .then((res?: HttpResponse<Object>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }

  async getCategoryNames(): Promise<Category[]> {
    const api_url = environment.api_endpoint + 'products/category-names';
    const response = await this.http.get<Category[]>(api_url, { headers: this.headers }).toPromise();
    if (response === undefined) {
      throw new Error('Failed to fetch third level names');
    }
    return response;
  }

  async getAllCountries(): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + 'countries/';
    var params = new HttpParams().appendAll({});
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true })
      .toPromise()
      .then((res?: HttpResponse<Object>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }

  async getCountriesNames(): Promise<Country[]> {
    const api_url = environment.api_endpoint + 'products/country-names';
    const response = await this.http.get<Country[]>(api_url, { headers: this.headers }).toPromise();
    if (response === undefined) {
      throw new Error('Failed to fetch first level names');
    }
    return response;
  }

  async getBrandNames(): Promise<Brand[]> {
    const api_url = environment.api_endpoint + 'products/brand-names';
    const response = await this.http.get<Brand[]>(api_url, { headers: this.headers }).toPromise();
    if (response === undefined) {
      throw new Error('Failed to fetch first level names');
    }
    return response;
  }

  async getBuyerNames(): Promise<string[]> {
    const api_url = environment.api_endpoint + 'users/names';
    const response = await this.http.get<string[]>(api_url, { headers: this.headers }).toPromise();
    if (response === undefined) {
      throw new Error('Failed to fetch first level names');
    }
    return response;
  }

  async getUsersNames(): Promise<User[]> {
    const api_url = environment.api_endpoint + 'products/user-names';
    const response = await this.http.get<User[]>(api_url, { headers: this.headers }).toPromise();
    if (response === undefined) {
      throw new Error('Failed to fetch first level names');
    }
    return response;
  }

  async getSupplierNames(): Promise<Supplier[]> {
    const api_url = environment.api_endpoint + 'products/supplier-names';
    const response = await this.http.get<Supplier[]>(api_url, { headers: this.headers }).toPromise();
    if (response === undefined) {
      throw new Error('Failed to fetch first level names');
    }
    return response;
  }


  //Methods about brands
  async getAllBrands(): Promise<any> {
    const api_url = environment.api_endpoint + 'brands';
    var params = new HttpParams().appendAll({});
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)
  }

  async addBrand(data: any): Promise<any> {
    const api_url = environment.api_endpoint + 'brands';
    try {
      const res = await this.http.post(api_url, data, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async updateBrand(brandId: number, updatebrandDto: any): Promise<any> {
    const api_url = `${environment.api_endpoint}brands/${brandId}`;
    try {
      const res = await this.http.put(api_url, updatebrandDto, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async approveBrand(brandId: number, approvebrandDto: any): Promise<any> {
    const api_url = `${environment.api_endpoint}brands/approve/${brandId}`;
    try {
      const res = await this.http.put(api_url, approvebrandDto, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async uploadImage(brand_id: number, url: string, type: string) {
    const api_url = `${environment.api_endpoint}brand_images`;
    try {
      const res = await this.http.post(api_url, { brand_id, url, type }, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async deleteImage(brandId: number, url: string) {
    const api_url = `${environment.api_endpoint}brand_images`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers, body: { brandId, url } }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async brandNameExists(name: string): Promise<any> {
    const url = `${environment.api_endpoint}brands/is_exist_brand_name/${name}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.get(url, {headers: headers}).toPromise();
      console.log('Brand name exists:', response);
      return response
    } catch (error) {
      console.error('Failed to get brand name', error);
      throw error;
    }
  }

  async addProductPriceChange(productPriceChange: ProductPriceChange): Promise<any> {
    const api_url = environment.api_endpoint + 'product-price-change';
    try {
      const res = await this.http.post(api_url, productPriceChange, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getAllProductPriceChanges(): Promise<any> {
    const api_url = environment.api_endpoint + 'product-price-change';
    var params = new HttpParams().appendAll({});
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)
  }

  async updateProductPriceChange(productPriceChangeId: number, updateProductPriceChangeDto: any): Promise<any> {
    const api_url = `${environment.api_endpoint}product-price-change/${productPriceChangeId}`;
    try {
      const res = await this.http.put(api_url, updateProductPriceChangeDto, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async deleteProductPriceChanges(id: number) {
    const api_url = `${environment.api_endpoint}product-price-change/${id}`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  //Methods about Locations
  async getAllLocations(): Promise<any> {
    const api_url = environment.api_endpoint + 'locations';
    var params = new HttpParams().appendAll({

    });
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)
  }

  async updateLocation(locationId: number, updateId: number, updateLocationDto: any): Promise<any> {
    const api_url = `${environment.api_endpoint}locations/${locationId}?location_id=${updateId}`;
    try {
      const res = await this.http.put(api_url, updateLocationDto, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async addLocation(location: Location): Promise<any> {
    const api_url = environment.api_endpoint + 'locations';
    try {
      const res = await this.http.post(api_url, location, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async disableLocation(location: Location): Promise<any> {
    const api_url = environment.api_endpoint + `locations/disable/${location.id}`;
    try {
      const res = await this.http.patch(api_url, {}, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  // Methods about Inventory_error
  async getAllInventoryError(): Promise<any> {
    const api_url = environment.api_endpoint + 'inventory_error';
    var params = new HttpParams().appendAll({});
    return this.http.get(api_url,
      { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true })
      .toPromise()
      .then(res => {
        return res;
      }).catch(error => error)
  }

  async updateSheet() {
    const apiUrl = `${environment.api_endpoint}products/update-template`;
    try {
      const response = await this.http.get(apiUrl, { responseType: 'blob' }).toPromise();
      if (!response) {
        throw new Error('Failed to retrieve the file.');
      }
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Import_Product_Template.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('下载模板时出错:', error);
      alert('下载模板失败，请稍后再试。');
    }
  }

  async reviewInventoryError(ids: number[], status: string, user_id: number): Promise<any> {
    const api_url = environment.api_endpoint + 'inventory_error/review';
    try {
      const res = await this.http.put(api_url, { ids, status, user_id }, { headers: this.headers, observe: 'response', responseType: 'json', withCredentials: true }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getInventoryDetail(collection_id: string): Promise<any> {
    const api_url = environment.api_endpoint + `shopify/inventory-collection/${collection_id}`;
    var params = new HttpParams().appendAll({});
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)
  }

  async getAllOrdersLastSevenDays(location_id: number): Promise<any> {
    const api_url = environment.api_endpoint + `shopify/orders-last-seven-days/${location_id}`;
    var params = new HttpParams().appendAll({});
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)
  }

  async getOrderDetail(): Promise<any> {
    const api_url = environment.api_endpoint + 'shopify/orders';
    var params = new HttpParams().appendAll({});
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)
  }

  async getAllWarehouseTransfer(): Promise<any> {
    const api_url = environment.api_endpoint + 'warehouse-transfer';
    var params = new HttpParams().appendAll({});
    return this.http.get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true }).toPromise()
      .then(res => {
        console.log(res)
        return res;
      }).catch(error => error)
  }

  async getWarehouseTransferDetail(id: number): Promise<any> {
    const api_url = `${environment.api_endpoint}warehouse-transfer/${id}/detail`;
    const params = new HttpParams();

    try {
      const res = await this.http.get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true
      }).toPromise();

      console.log('Warehouse transfer detail:', res);
      return res;
    } catch (error) {
      console.error('Error fetching warehouse transfer detail:', error);
      throw error;
    }
  }

  async addWarehouseTransfer(transfer: any): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.post(url, transfer, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to add transfer', error);
      throw error;
    }
  }

  async addWarehouseTransferByFranchise(transfer: any): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/franchise`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.post(url, transfer, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to add transfer', error);
      throw error;
    }
  }

  async updateWarehouseTransfer(id: number, transfer: any): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/${id}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.put(url, transfer, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to add transfer', error);
      throw error;
    }
  }

  async updateWarehouseTransferDate(
    id: number,
    transfer_date: Date,
    updated_by: number
  ): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/${id}/transfer-date`;
    const body = { transfer_date, updated_by };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.put(url, body, {
        headers,
        observe: 'response',
        responseType: 'json',
        withCredentials: true
      }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to update transfer date', error);
      throw error;
    }
  }

  async approveWarehouseTransfer(id: number, transfer: any): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/approve/${id}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.put(url, transfer, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to add transfer', error);
      throw error;
    }
  }

  async deleteWarehouseTransfer(id: number): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/${id}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.delete(url, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to delete transfer', error);
      throw error;
    }
  }

  async getWarehouseReservedInventory(shopify_id: string): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/reserved-inventory/${shopify_id}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.get(url, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to get reserved inventory', error);
      throw error;
    }
  }

  async syncWarehouseTransferToShopify(id: number): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/sync-shopify/${id}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.post(url, {}, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async reverseSyncWarehouseTransferToShopify(id: number): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/sync-shopify-reverse/${id}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.post(url, {}, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async batchSyncWarehouseTransferToShopify(ids: number[]): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/batch-sync-shopify`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.post(url, { ids }, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllStockTakes(): Promise<StockTake[]> {
    const api_url = environment.api_endpoint + 'stock-take';
    var params = new HttpParams().appendAll({});
    return this.http.get<StockTake[]>(api_url, { headers: this.headers, params: params, withCredentials: true })
      .toPromise()
      .then((res: StockTake[] | undefined) => {
        console.log(res);
        return res || [];
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }

  async getStockTakeDetail(id: number): Promise<any> {
    const api_url = `${environment.api_endpoint}stock-take/${id}`;
    const params = new HttpParams();

    try {
      const res = await this.http.get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true
      }).toPromise();

      console.log('Warehouse transfer detail:', res);
      return res;
    } catch (error) {
      console.error('Error fetching warehouse transfer detail:', error);
      throw error;
    }
  }

  async syncStockTakeToShopify(id: number): Promise<any> {
    const url = `${environment.api_endpoint}stock-take/sync-shopify/${id}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.post(url, {}, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to sync stock take to Shopify', error);
      throw error;
    }
  }

  async getProductInventory(product_id: string): Promise<any> {
    const url = `${environment.api_endpoint}shopify/inventories/${product_id}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.get(url, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to sync stock take to Shopify', error);
      throw error;
    }
  }

  async syncProductsFromShopify(): Promise<any> {
    const url = `${environment.api_endpoint}shopify/sync-shopify-products`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.post(url, {}, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to sync products from Shopify', error);
      throw error;
    }
  }

  async syncProductToShopify(product_id: number): Promise<any> {
    const url = `${environment.api_endpoint}shopify/sync-product/${product_id}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.post(url, {}, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to sync products from Shopify', error);
      throw error;
    }
  }

  async getLastSevenDaysTransferData(startDate: string): Promise<any> {
    const api_url = `${environment.api_endpoint}warehouse-transfer/last-seven-days/${startDate}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.get(api_url, {
        headers,
        observe: 'response',
        responseType: 'json',
        withCredentials: true
      }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to get last seven days transfer data', error);
      throw error;
    }
  }

  async getBetweenTransferData(startDate: string, endDate: string): Promise<any> {
    const api_url = `${environment.api_endpoint}warehouse-transfer/between-dates/${startDate}/${endDate}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    try {
      const response = await this.http.get(api_url, {
        headers,
        observe: 'response',
        responseType: 'json',
        withCredentials: true
      }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to get between days transfer data', error);
      throw error;
    }
  }
}
