import { NzTableSize, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';

const USER_STATUS_MAP = {
  "": "",
  "active": "有效",
  "archived": "停用",
  "deleted": "刪除",
}


const Tr_MAP_LOCATION_STATUS = {
  "": "",
   "營業中":"active",
   "已關店": "closed",
}

const Tr_MAP_LOCATION_TYPES = {
  "": "",
   "倉庫":"warehouse",
  "直營店":"store",
  "加盟店":"franchise",
}

const LOCATION_STATUS_MAP = {
  "": "",
  "active": "營業中",
  "closed": "已關店",
}

const LOCATION_TYPES_MAP = {
  "": "",
  "warehouse": "倉庫",
  "store":"直營店",
  "franchise":"加盟店",
}

const WAREHOUSE_TRANSFER_STATUS_MAP = new Map<string, string>(
  [
    ['pending', '待審核'],
    ['new', '待處理'],
    ['packed', '執貨完成'],
    ['syncing', '同步中'],
    ['synced', '已同步'],
    ['receiving', '收貨中'],
    ['received', '已收貨'],
    ['finished', '已完成'],
  ]
);
const WAREHOUSE_TRANSFER_STATUS_BADGE_MAP = new Map<string, string>(
  [
    ['pending', 'processing'],
    ['new', 'processing'],
    ['packed', 'processing'],
    ['syncing', 'processing'],
    ['synced', 'success'],
    ['receiving', 'processing'],
    ['received', 'processing'],
    ['finished', 'success'],
  ]
);

const STOCK_TAKE_STATUS_MAP = new Map<string, string>(
  [
    ["new", "待處理"],
    ["syncing", "同步中"],
    ["synced", "已同步"],
  ]
);

const STOCK_TAKE_STATUS_BADGE_MAP = new Map<string, string>(
  [
    ['new', 'processing'],
    ['syncing', 'processing'],
    ['synced', 'success'],
  ]
);

const SHOPIFY_CATEGORIES: Map<string, string[]> = new Map([
  ["沖飲", ["沖飲麥片穀物", "沖飲茶", "即溶咖啡", "奶粉", "沖飲奶茶", "豆奶粉", "其他沖飲食品"]],
  ["酒", ["啤酒", "雞尾酒", "果酒", "葡萄酒", "氣泡酒", "燒酒", "日本清酒"]],
  ["飲品", ["飲用水", "茶", "功能飲品", "果蔬汁", "果汁", "奶茶", "植物蛋白飲料", "即飲咖啡", "碳酸飲品", "乳酸菌飲品", "純牛奶", "調味奶", "乳酪", "蘇打水"]],
  ["肉脯滷味", ["海味零食", "麵筋", "辣條", "鹵蛋", "雞肉零食", "鴨肉零食", "魔芋製品", "豬肉零食", "牛肉零食", "豆干", "素肉", "臘腸"]],
  ["方便食品", ["即食麵", "火腿腸", "自熱即食火鍋", "肉類罐頭", "速食菜", "速食湯", "自熱米飯", "罐頭食品"]],
  ["膨化食品", ["薯片", "薯條", "蝦片蝦條", "鍋巴", "海苔", "米餅", "洋蔥圈", "爆米花", "乾脆面", "泡芙", "麵包粒"]],
  ["糕點餅乾", ["威化餅乾", "夾心餅乾", "蘇打餅乾", "曲奇", "中式糕點點心", "西式糕點點心", "蛋捲", "能量棒"]],
  ["堅果果乾蜜餞", ["蜜餞", "果乾", "瓜子", "堅果", "花生", "其他果乾蜜餞"]],
  ["糖巧果凍", ["巧克力", "糖果", "果凍", "布丁", "口香糖", "奶片"]],
  ["糧油調味", ["調味醬", "罐頭", "五穀雜糧", "食用油", "熏臘製品", "醋", "酵母粉", "粉末"]],
  ["凍食", ["雪糕", "雪條"]],
  ["寵物生活", ["貓條", "寵物凍幹", "寵物罐頭", "寵物肉乾肉條", "潔齒骨", "貓砂", "寵物糧"]],
  ["玩具盲盒", ["模型玩具", "盲盒", "橡皮泥", "動漫玩具", "積木", "拼圖", "毛絨玩具"]],
  ["保健醫療", ["保健飲料", "維生素", "鈣片", "益生菌", "其他營養保健", "貼", "藥油", "藥膏", "蜂巢", "西藥", "中藥"]],
  ["個人護理", ["沐浴露", "牙刷", "漱口水", "牙膏", "護墊", "衛生褲", "衛生巾", "洗髮水", "髮膜", "護髮素", "染髮", "其他護髮產品", "浴鹽", "止汗劑", "舒緩軟貼", "口腔噴霧", "口罩清新噴霧", "女性護理", "刮鬍產品", "肥皂", "足部護理產品"]],
  ["彩妝護膚", ["潔面", "面膜", "精華", "卸妝", "潤唇膏", "爽膚水", "面霜", "防曬", "眼霜", "乳液", "工具", "散粉", "眼部化妝品", "香水", "身體乳液", "護手霜", "胭脂", "痘痘治療", "粉底液", "定妝噴霧", "修容粉", "美白產品", "保濕噴霧", "身體磨砂"]],
  ["居家日用", ["厨紙", "抽紙", "捲紙", "洗臉巾", "垃圾袋", "洗衣凝珠", "濕紙巾", "衣物柔順劑", "驅蚊驅蟲", "家紡生活用品", "保鮮袋", "潔廁劑", "棉簽", "鞋用除臭劑", "空氣清洗劑", "洗衣液", "洗衣粉", "洗潔精", "廚房清潔劑", "漂白水", "清潔用品", "抽濕及防潮", "暖杯墊", "消毒噴霧", "潔手皂液", "香薰蠟燭", "其他家居用品", "數碼娛樂", "文具", "生活電器", "耳機", "暖杯墊", "香薰機"]]
]);

const COUNTRY_MAP = {
  "": "",
  "Afghanistan": "阿富汗",
  "Albania": "阿爾巴尼亞",
  "Algeria": "阿爾及利亞",
  "American Samoa": "美屬薩摩亞",
  "Andorra": "安道爾",
  "Angola": "安哥拉",
  "Anguilla": "安圭拉島",
  "Antarctica": "南極洲",
  "Antigua and Barbuda": "安提瓜和巴布達",
  "Argentina": "阿根廷",
  "Armenia": "亞美尼亞",
  "Aruba": "阿魯巴",
  "Australia": "澳洲",
  "Austria": "奧地利",
  "Azerbaijan": "阿塞拜疆",
  "Bahamas": "巴哈馬",
  "Bahrain": "巴林",
  "Bangladesh": "孟加拉",
  "Barbados": "巴巴多斯",
  "Belarus": "白俄羅斯",
  "Belgium": "比利時",
  "Belize": "伯利茲",
  "Benin": "貝寧",
  "Bermuda": "百慕達",
  "Bhutan": "不丹",
  "Bolivia, Plurinational State of": "玻利維亞",
  "Bosnia and Herzegovina": "波黑",
  "Botswana": "博茨瓦納",
  "Bouvet Island": "鮑威特島",
  "Brazil": "巴西",
  "British Indian Ocean Territory": "英屬印度洋地區",
  "Brunei Darussalam": "汶萊",
  "Bulgaria": "保加利亞",
  "Burkina Faso": "布基納法索",
  "Burundi": "布隆迪",
  "Cambodia": "柬埔寨",
  "Cameroon": "喀麥隆",
  "Canada": "加拿大",
  "Cape Verde": "佛得角",
  "Cayman Islands": "開曼群島",
  "Central African Republic": "中非",
  "Chad": "乍得",
  "Chile": "智利",
  "China": "中國",
  "Christmas Island": "聖誕島",
  "Cocos (Keeling) Islands": "科科斯群島",
  "Colombia": "哥倫比亞",
  "Comoros": "科摩羅",
  "Congo": "剛果",
  "Congo, the Democratic Republic of the": "剛果民主共和國",
  "Cook Islands": "庫克群島",
  "Costa Rica": "哥斯達黎加",
  "Croatia": "克羅地亞",
  "Cuba": "古巴",
  "Curacao": "庫拉索",
  "Cyprus": "塞浦路斯",
  "Czech Republic": "捷克",
  "Cote d'Ivoire": "科特迪瓦",
  "Denmark": "丹麥",
  "Djibouti": "吉布提",
  "Dominica": "多米尼克",
  "Dominican Republic": "多明尼加",
  "Ecuador": "厄瓜多爾",
  "Egypt": "埃及",
  "El Salvador": "薩爾瓦多",
  "Equatorial Guinea": "赤道幾內亞",
  "Eritrea": "厄立特里亞",
  "Estonia": "愛沙尼亞",
  "Ethiopia": "埃塞俄比亞",
  "Falkland Islands (Malvinas)": "福克蘭群島（馬爾維納斯）",
  "FaroeIslands": "法羅群島",
  "Fiji": "斐濟",
  "Finland": "芬蘭",
  "France": "法國",
  "French Guiana": "法屬圭亞那",
  "French Polynesia": "法屬波利尼西亞",
  "French Southern Territories": "法屬南部地區",
  "Gabon": "加蓬",
  "Gambia": "岡比亞",
  "Georgia": "格魯吉亞",
  "Germany": "德國",
  "Ghana": "加納",
  "Gibraltar": "直布羅陀",
  "Greece": "希臘",
  "Greenland": "格陵蘭",
  "Grenada": "格林納達",
  "Guadeloupe": "瓜德魯普島",
  "Guam": "關島",
  "Guatemala": "危地馬拉",
  "Guernsey": "根西島",
  "Guinea": "幾內亞",
  "Guinea-Bissau": "幾內亞比紹",
  "Guyana": "圭亞那",
  "Haiti": "海地",
  "Heard Island and McDonald Islands": "赫德島和麥克唐納群島",
  "Honduras": "宏都拉斯",
  "Hong Kong": "香港",
  "Hungary": "匈牙利",
  "Iceland": "冰島",
  "India": "印度",
  "Indonesia": "印尼",
  "Iran, Islamic Republic of": "伊朗",
  "Iraq": "伊拉克",
  "Ireland": "愛爾蘭",
  "Isle of Man": "萌島",
  "Israel": "以色列",
  "Italy": "意大利",
  "Jamaica": "牙買加",
  "Japan": "日本",
  "Jersey": "澤西",
  "Jordan": "約旦",
  "Kazakhstan": "哈薩克",
  "Kenya": "肯尼亞",
  "Kiribati": "基里巴斯",
  "Korea, Democratic People's Republic of": "北韓",
  "Korea, Republic of": "韓國",
  "Kuwait": "科威特",
  "Kyrgyzstan": "吉爾吉斯",
  "Lao People's Democratic Republic": "老撾",
  "Latvia": "拉脫維亞",
  "Lebanon": "黎巴嫩",
  "Lesotho": "萊索托",
  "Liberia": "利比里亞",
  "Libya": "利比亞",
  "Liechtenstein": "列支敦士登",
  "Lithuania": "立陶宛",
  "Luxembourg": "盧森堡",
  "Macau": "澳門",
  "Madagascar": "馬達加斯加",
  "Malawi": "馬拉維",
  "Malaysia": "馬來西亞",
  "Maldives": "馬爾代夫",
  "Mali": "馬里",
  "Malta": "馬爾他",
  "Marshall Islands": "馬紹爾群島",
  "Martinique": "馬提尼克",
  "Mauritania": "毛里塔尼亞",
  "Mauritius": "毛里求斯",
  "Mayotte": "馬約特",
  "Mexico": "墨西哥",
  "Micronesia, Federated States of": "密克羅尼西亞",
  "Moldova, Republic of": "摩爾多瓦",
  "Monaco": "摩納哥",
  "Mongolia": "蒙古",
  "Montenegro": "黑山",
  "Montserrat": "蒙塞拉特島",
  "Morocco": "摩洛哥",
  "Mozambique": "莫桑比克",
  "Myanmar": "緬甸",
  "Namibia": "納米比亞",
  "Nauru": "瑙魯",
  "Nepal": "尼泊爾",
  "Netherlands": "荷蘭",
  "New Caledonia": "新喀里多尼亞",
  "New Zealand": "新西蘭",
  "Nicaragua": "尼加拉瓜",
  "Niger": "尼日爾",
  "Nigeria": "尼日利亞",
  "Niue": "紐埃",
  "Norfolk Island": "諾福克島",
  "North Macedonia": "北馬其頓",
  "Northern Mariana Islands": "北馬里亞納群島",
  "Norway": "挪威",
  "Oman": "阿曼",
  "Pakistan": "巴基斯坦",
  "Palau": "帛琉",
  "Panama": "巴拿馬",
  "Papua New Guinea": "巴布亞新幾內亞",
  "Paraguay": "巴拉圭",
  "Peru": "秘魯",
  "Philippines": "菲律賓",
  "Pitcairn": "皮特凱恩群島",
  "Poland": "波蘭",
  "Portugal": "葡萄牙",
  "PuertoRico": "波多黎各",
  "Qatar": "卡塔爾",
  "Romania": "羅馬尼亞",
  "Russian Federation": "俄羅斯",
  "Rwanda": "盧旺達",
  "Réunion": "留尼汪",
  "Saint Barthelemy": "聖巴托洛繆島",
  "Saint Kitts and Nevis": "聖基茨和尼維斯",
  "Saint Lucia": "聖盧西亞",
  "Saint Martin (French part)": "法屬聖馬丁",
  "Saint Pierre and Miquelon": "聖皮埃爾島和密克隆島",
  "Saint Vincent and the Grenadines": "聖文森特和格林納丁斯",
  "Samoa": "薩摩亞",
  "San Marino": "聖馬力諾",
  "Sao Tome and Principe": "聖多美及普林西比",
  "Saudi Arabia": "沙特阿拉伯",
  "Senegal": "塞內加爾",
  "Serbia": "塞爾維亞",
  "Seychelles": "塞舌爾",
  "SierraLeone": "塞拉利昂",
  "Singapore": "新加坡",
  "Sint Maarten (Dutch part)": "荷屬聖馬丁",
  "Slovakia": "斯洛伐克",
  "Slovenia": "斯洛文尼亞",
  "Solomon Islands": "所羅門群島",
  "Somalia": "索馬里",
  "South Africa": "南非",
  "South Georgia and the South Sandwich Islands": "南喬治亞島與南桑威奇群島",
  "South Sudan": "南蘇丹",
  "Spain": "西班牙",
  "SriLanka": "斯里蘭卡",
  "Sudan": "蘇丹",
  "Suriname": "蘇里南",
  "Svalbard and Jan Mayen": "斯瓦爾巴特群島",
  "Swaziland": "斯威士蘭",
  "Sweden": "瑞典",
  "Switzerland": "瑞士",
  "Syrian Arab Republic": "敘利亞",
  "Taiwan": "台灣",
  "Tajikistan": "塔吉克",
  "Tanzania": "坦桑尼亞",
  "Thailand": "泰國",
  "Timor-Leste": "東帝汶",
  "Togo": "多哥",
  "Tokelau": "托克勞群島",
  "Tonga": "湯加",
  "Trinidad and Tobago": "千里達和多巴哥",
  "Tunisia": "突尼斯",
  "Turkey": "土耳其",
  "Turkmenistan": "土庫曼",
  "Turks and Caicos Islands": "特克斯和凱科斯群島",
  "Tuvalu": "圖瓦盧",
  "Uganda": "烏干達",
  "Ukraine": "烏克蘭",
  "United Arab Emirates": "阿聯酋",
  "United Kingdom": "英國",
  "United States": "美國",
  "United States Minor Outlying Islands": "美國海外小島",
  "Uruguay": "烏拉圭",
  "Uzbekistan": "烏茲別克",
  "Vanuatu": "瓦努阿圖",
  "VaticanCity": "梵蒂岡",
  "Venezuela, Bolivarian Republic of": "委內瑞拉",
  "Viet Nam": "越南",
  "Virgin Islands, British": "英屬處女群島",
  "Virgin Islands, U.S.": "美屬處女群島",
  "Wallis and Futuna": "瓦利斯群島和富圖納群島",
  "Western Sahara": "西撒哈拉",
  "Yemen": "也門",
  "Zambia": "贊比亞",
  "Zimbabwe": "津巴布韋",
  "Aland Islands": "亞蘭群島",
}

interface TableSizeItem {
  sizeName: string;
  selected: boolean;
  value: NzTableSize;
}

const TABLE_SIZE_OPTIONS: TableSizeItem[] = [
  { sizeName: '默認', selected: false, value: 'default' },
  { sizeName: '中等', selected: true, value: 'middle' },
  { sizeName: '緊湊', selected: false, value: 'small' }
];

const SUPPLIER_STATUS_MAP = {
  "": "",
  "active": "有效",
  "archived": "停用",
  "deleted": "刪除",
  "true": "是",
  "false": "否"
}

const SUPPLIER_REGION_MAP = {
  "": "",
  "taiwan": "台灣",
  "hongkong": "香港",
  "japan": "日本",
  "china": "中國",
}

const SUPPLIER_TYPE_MAP = {
  "": "",
  "brand_owner": "品牌方",
  "distributor": "經銷商",
  "factory": "工廠",
  "scalper": "黃牛",
}

const PURCHASEORDER_STATUS_MAP = {
  "": "",
  "Approved": "已核准",
  "Pending": "處理中",
  "Rejected": "已拒絕",
  "true": "是",
  "false": "否",
  "Paid": "已付款",
  "Arrived": "已到貨",
}

const BRAND_STATUS_MAP = {
  "Approved": "已通過",
  "Pending": "審核中",
  "Rejected": "已拒絕",
}

const SPECS_STATUS_MAP = {
  "1": "默認值",
  "0": "否"
};

const BRAND_RANKS = ["國際一線", "國際二線", "國內一線", "國內二線", "港澳台一線", "港澳台二線", "雜牌"];

const BANKS = [
  { "code": "003", "name": "Standard Chartered Bank (Hong Kong) Limited" },
  { "code": "004", "name": "The Hongkong and Shanghai Banking Corporation Limited" },
  { "code": "009", "name": "China Construction Bank (Asia) Corporation Limited" },
  { "code": "012", "name": "Bank of China (Hong Kong) Limited" },
  { "code": "014", "name": "Bank of China (Hong Kong) Limited" },
  { "code": "019", "name": "Bank of China (Hong Kong) Limited" },
  { "code": "026", "name": "Bank of China (Hong Kong) Limited" },
  { "code": "030", "name": "Bank of China (Hong Kong) Limited" },
  { "code": "031", "name": "Bank of China (Hong Kong) Limited" },
  { "code": "033", "name": "Bank of China (Hong Kong) Limited" },
  { "code": "036", "name": "Bank of China (Hong Kong) Limited" },
  { "code": "064", "name": "Bank of China (Hong Kong) Limited" },
  { "code": "070", "name": "Bank of China (Hong Kong) Limited" },
  { "code": "015", "name": "The Bank of East Asia, Limited" },
  { "code": "016", "name": "DBS Bank (Hong Kong) Limited" },
  { "code": "032", "name": "DBS Bank (Hong Kong) Limited" },
  { "code": "052", "name": "DBS Bank (Hong Kong) Limited" },
  { "code": "185", "name": "DBS Bank Ltd, Hong Kong Branch" },
  { "code": "018", "name": "China CITIC Bank International Limited" },
  { "code": "020", "name": "CMB Wing Lung Bank Limited" },
  { "code": "022", "name": "Oversea-Chinese Banking Corporation Ltd" },
  { "code": "024", "name": "Hang Seng Bank Limited" },
  { "code": "025", "name": "Shanghai Commercial Bank Limited" },
  { "code": "027", "name": "Bank of Communications Co., Ltd." },
  { "code": "382", "name": "Bank of Communications (Hong Kong) Limited" },
  { "code": "028", "name": "Public Bank (Hong Kong) Limited" },
  { "code": "035", "name": "OCBC Bank (Hong Kong) Limited" },
  { "code": "038", "name": "Tai Yau Bank Limited" },
  { "code": "039", "name": "Chiyu Banking Corporation Limited" },
  { "code": "040", "name": "Dah Sing Bank Limited" },
  { "code": "041", "name": "Chong Hing Bank Limited" },
  { "code": "043", "name": "Nanyang Commercial Bank, Limited" },
  { "code": "050", "name": "Indian Overseas Bank" },
  { "code": "060", "name": "National Bank Of Pakistan" },
  { "code": "061", "name": "Tai Sang Bank Limited" },
  { "code": "063", "name": "Malayan Banking Berhad (Maybank)" },
  { "code": "066", "name": "PT. Bank Negara Indonesia (Persero) TBK." },
  { "code": "071", "name": "United Overseas Bank Ltd." },
  { "code": "072", "name": "Industrial and Commercial Bank of China (Asia) Limited" },
  { "code": "082", "name": "State Bank of India" },
  { "code": "128", "name": "Fubon Bank (Hong Kong) Limited" },
  { "code": "198", "name": "Hua Nan Commercial Bank, Ltd (Hong Kong Branch)" },
  { "code": "203", "name": "First Commercial Bank" },
  { "code": "214", "name": "Industrial and Commercial Bank of China Limited" },
  { "code": "221", "name": "China Construction Bank Corporation" },
  { "code": "222", "name": "Agricultural Bank of China Limited (Hong Kong Branch)" },
  { "code": "229", "name": "CTBC Bank Co., Ltd." },
  { "code": "238", "name": "China Merchants Bank Co., Ltd. (Hong Kong Branch)" },
  { "code": "006", "name": "Citibank N.A." },
  { "code": "250", "name": "Citibank (Hong Kong) Limited" },
  { "code": "269", "name": "The Shanghai Commercial & Savings Bank, Ltd." },
  { "code": "353", "name": "China Minsheng Banking Corp., Ltd. (Hong Kong Branch)" },
  { "code": "368", "name": "China Everbright Bank Co., Ltd. (Hong Kong Branch)" },
  { "code": "377", "name": "Industrial Bank Co., Ltd. (Hong Kong Branch)" },
  { "code": "378", "name": "Yuanta Commercial Bank Co. Ltd." },
  { "code": "387", "name": "ZA Bank Limited" },
  { "code": "388", "name": "Livi Bank Limited" },
  { "code": "389", "name": "Mox Bank Limited" },
  { "code": "390", "name": "Welab Bank Limited" },
  { "code": "391", "name": "Fusion Bank Limited" },
  { "code": "395", "name": "Airstar Bank Limited" }
];

const COUNTRIES = [
  {
    ISO2: "AF",
    ISO3: "AFG",
    code: "004",
    name_EN: "Afghanistan",
    name_ZH: "阿富汗",
    name_CN: "阿富汗"
  },
  {
    ISO2: "AL",
    ISO3: "ALB",
    code: "008",
    name_EN: "Albania",
    name_ZH: "阿爾巴尼亞",
    name_CN: "阿尔巴尼亚"
  },
  {
    ISO2: "DZ",
    ISO3: "DZA",
    code: "012",
    name_EN: "Algeria",
    name_ZH: "阿爾及利亞",
    name_CN: "阿尔及利亚"
  },
  {
    ISO2: "AS",
    ISO3: "ASM",
    code: "016",
    name_EN: "American Samoa",
    name_ZH: "美屬薩摩亞",
    name_CN: "美属萨摩亚"
  },
  {
    ISO2: "AD",
    ISO3: "AND",
    code: "020",
    name_EN: "Andorra",
    name_ZH: "安道爾",
    name_CN: "安道尔"
  },
  {
    ISO2: "AO",
    ISO3: "AGO",
    code: "024",
    name_EN: "Angola",
    name_ZH: "安哥拉",
    name_CN: "安哥拉"
  },
  {
    ISO2: "AI",
    ISO3: "AIA",
    code: "660",
    name_EN: "Anguilla",
    name_ZH: "安圭拉島",
    name_CN: "安圭拉"
  },
  {
    ISO2: "AQ",
    ISO3: "ATA",
    code: "010",
    name_EN: "Antarctica",
    name_ZH: "南極洲",
    name_CN: "南极洲"
  },
  {
    ISO2: "AG",
    ISO3: "ATG",
    code: "028",
    name_EN: "Antigua and Barbuda",
    name_ZH: "安提瓜和巴布達",
    name_CN: "安提瓜和巴布达"
  },
  {
    ISO2: "AR",
    ISO3: "ARG",
    code: "032",
    name_EN: "Argentina",
    name_ZH: "阿根廷",
    name_CN: "阿根廷"
  },
  {
    ISO2: "AM",
    ISO3: "ARM",
    code: "051",
    name_EN: "Armenia",
    name_ZH: "亞美尼亞",
    name_CN: "亚美尼亚"
  },
  {
    ISO2: "AW",
    ISO3: "ABW",
    code: "533",
    name_EN: "Aruba",
    name_ZH: "阿魯巴",
    name_CN: "阿鲁巴"
  },
  {
    ISO2: "AU",
    ISO3: "AUS",
    code: "036",
    name_EN: "Australia",
    name_ZH: "澳洲",
    name_CN: "澳大利亚"
  },
  {
    ISO2: "AT",
    ISO3: "AUT",
    code: "040",
    name_EN: "Austria",
    name_ZH: "奧地利",
    name_CN: "奥地利"
  },
  {
    ISO2: "AZ",
    ISO3: "AZE",
    code: "031",
    name_EN: "Azerbaijan",
    name_ZH: "阿塞拜疆",
    name_CN: "阿塞拜疆"
  },
  {
    ISO2: "BS",
    ISO3: "BHS",
    code: "044",
    name_EN: "Bahamas",
    name_ZH: "巴哈馬",
    name_CN: "巴哈马"
  },
  {
    ISO2: "BH",
    ISO3: "BHR",
    code: "048",
    name_EN: "Bahrain",
    name_ZH: "巴林",
    name_CN: "巴林"
  },
  {
    ISO2: "BD",
    ISO3: "BGD",
    code: "050",
    name_EN: "Bangladesh",
    name_ZH: "孟加拉",
    name_CN: "孟加拉国"
  },
  {
    ISO2: "BB",
    ISO3: "BRB",
    code: "052",
    name_EN: "Barbados",
    name_ZH: "巴巴多斯",
    name_CN: "巴巴多斯"
  },
  {
    ISO2: "BY",
    ISO3: "BLR",
    code: "112",
    name_EN: "Belarus",
    name_ZH: "白俄羅斯",
    name_CN: "白俄罗斯"
  },
  {
    ISO2: "BE",
    ISO3: "BEL",
    code: "056",
    name_EN: "Belgium",
    name_ZH: "比利時",
    name_CN: "比利时"
  },
  {
    ISO2: "BZ",
    ISO3: "BLZ",
    code: "084",
    name_EN: "Belize",
    name_ZH: "伯利茲",
    name_CN: "伯利兹"
  },
  {
    ISO2: "BJ",
    ISO3: "BEN",
    code: "204",
    name_EN: "Benin",
    name_ZH: "貝寧",
    name_CN: "贝宁"
  },
  {
    ISO2: "BM",
    ISO3: "BMU",
    code: "060",
    name_EN: "Bermuda",
    name_ZH: "百慕達",
    name_CN: "百慕大"
  },
  {
    ISO2: "BT",
    ISO3: "BTN",
    code: "064",
    name_EN: "Bhutan",
    name_ZH: "不丹",
    name_CN: "不丹"
  },
  {
    ISO2: "BO",
    ISO3: "BOL",
    code: "068",
    name_EN: "Bolivia (Plurinational State of)",
    name_ZH: "玻利維亞",
    name_CN: "玻利维亚"
  },
  {
    ISO2: "BQ",
    ISO3: "BES",
    code: "535",
    name_EN: "Bonaire, Sint Eustatius and Saba",
    name_ZH: "荷蘭加勒比區",
    name_CN: "荷兰加勒比区"
  },
  {
    ISO2: "BA",
    ISO3: "BIH",
    code: "070",
    name_EN: "Bosnia and Herzegovina",
    name_ZH: "波黑",
    name_CN: "波黑"
  },
  {
    ISO2: "BW",
    ISO3: "BWA",
    code: "072",
    name_EN: "Botswana",
    name_ZH: "博茨瓦納",
    name_CN: "博茨瓦纳"
  },
  {
    ISO2: "BV",
    ISO3: "BVT",
    code: "074",
    name_EN: "Bouvet Island",
    name_ZH: "鮑威特島",
    name_CN: "布韦岛"
  },
  {
    ISO2: "BR",
    ISO3: "BRA",
    code: "076",
    name_EN: "Brazil",
    name_ZH: "巴西",
    name_CN: "巴西"
  },
  {
    ISO2: "IO",
    ISO3: "IOT",
    code: "086",
    name_EN: "British Indian Ocean Territory",
    name_ZH: "英屬印度洋地區",
    name_CN: "英属印度洋领地"
  },
  {
    ISO2: "BN",
    ISO3: "BRN",
    code: "096",
    name_EN: "Brunei Darussalam",
    name_ZH: "汶萊",
    name_CN: "文莱"
  },
  {
    ISO2: "BG",
    ISO3: "BGR",
    code: "100",
    name_EN: "Bulgaria",
    name_ZH: "保加利亞",
    name_CN: "保加利亚"
  },
  {
    ISO2: "BF",
    ISO3: "BFA",
    code: "854",
    name_EN: "Burkina Faso",
    name_ZH: "布基納法索",
    name_CN: "布基纳法索"
  },
  {
    ISO2: "BI",
    ISO3: "BDI",
    code: "108",
    name_EN: "Burundi",
    name_ZH: "布隆迪",
    name_CN: "布隆迪"
  },
  {
    ISO2: "KH",
    ISO3: "KHM",
    code: "116",
    name_EN: "Cambodia",
    name_ZH: "柬埔寨",
    name_CN: "柬埔寨"
  },
  {
    ISO2: "CM",
    ISO3: "CMR",
    code: "120",
    name_EN: "Cameroon",
    name_ZH: "喀麥隆",
    name_CN: "喀麦隆"
  },
  {
    ISO2: "CA",
    ISO3: "CAN",
    code: "124",
    name_EN: "Canada",
    name_ZH: "加拿大",
    name_CN: "加拿大"
  },
  {
    ISO2: "CV",
    ISO3: "CPV",
    code: "132",
    name_EN: "Cape Verde",
    name_ZH: "佛得角",
    name_CN: "佛得角"
  },
  {
    ISO2: "KY",
    ISO3: "CYM",
    code: "136",
    name_EN: "Cayman Islands",
    name_ZH: "開曼群島",
    name_CN: "开曼群岛"
  },
  {
    ISO2: "CF",
    ISO3: "CAF",
    code: "140",
    name_EN: "Central African Republic",
    name_ZH: "中非",
    name_CN: "中非"
  },
  {
    ISO2: "TD",
    ISO3: "TCD",
    code: "148",
    name_EN: "Chad",
    name_ZH: "乍得",
    name_CN: "乍得"
  },
  {
    ISO2: "CL",
    ISO3: "CHL",
    code: "152",
    name_EN: "Chile",
    name_ZH: "智利",
    name_CN: "智利"
  },
  {
    ISO2: "CN",
    ISO3: "CHN",
    code: "156",
    name_EN: "China",
    name_ZH: "中國",
    name_CN: "中国"
  },
  {
    ISO2: "CX",
    ISO3: "CXR",
    code: "162",
    name_EN: "Christmas Island",
    name_ZH: "聖誕島",
    name_CN: "圣诞岛"
  },
  {
    ISO2: "CC",
    ISO3: "CCK",
    code: "166",
    name_EN: "Cocos (Keeling) Islands",
    name_ZH: "科科斯群島",
    name_CN: "科科斯群岛"
  },
  {
    ISO2: "CO",
    ISO3: "COL",
    code: "170",
    name_EN: "Colombia",
    name_ZH: "哥倫比亞",
    name_CN: "哥伦比亚"
  },
  {
    ISO2: "KM",
    ISO3: "COM",
    code: "174",
    name_EN: "Comoros",
    name_ZH: "科摩羅",
    name_CN: "科摩罗"
  },
  {
    ISO2: "CG",
    ISO3: "COG",
    code: "178",
    name_EN: "Congo",
    name_ZH: "剛果",
    name_CN: "刚果"
  },
  {
    ISO2: "CD",
    ISO3: "COD",
    code: "180",
    name_EN: "Congo (Democratic Republic of the)",
    name_ZH: "剛果民主共和國",
    name_CN: "刚果民主共和国"
  },
  {
    ISO2: "CK",
    ISO3: "COK",
    code: "184",
    name_EN: "Cook Islands",
    name_ZH: "庫克群島",
    name_CN: "库克群岛"
  },
  {
    ISO2: "CR",
    ISO3: "CRI",
    code: "188",
    name_EN: "Costa Rica",
    name_ZH: "哥斯達黎加",
    name_CN: "哥斯达黎加"
  },
  {
    ISO2: "HR",
    ISO3: "HRV",
    code: "191",
    name_EN: "Croatia",
    name_ZH: "克羅地亞",
    name_CN: "克罗地亚"
  },
  {
    ISO2: "CU",
    ISO3: "CUB",
    code: "192",
    name_EN: "Cuba",
    name_ZH: "古巴",
    name_CN: "古巴"
  },
  {
    ISO2: "CW",
    ISO3: "CUW",
    code: "531",
    name_EN: "Curaçao",
    name_ZH: "庫拉索",
    name_CN: "库拉索"
  },
  {
    ISO2: "CY",
    ISO3: "CYP",
    code: "196",
    name_EN: "Cyprus",
    name_ZH: "塞浦路斯",
    name_CN: "塞浦路斯"
  },
  {
    ISO2: "CZ",
    ISO3: "CZE",
    code: "203",
    name_EN: "Czech Republic",
    name_ZH: "捷克",
    name_CN: "捷克"
  },
  {
    ISO2: "CI",
    ISO3: "CIV",
    code: "384",
    name_EN: "Côte d'Ivoire",
    name_ZH: "科特迪瓦",
    name_CN: "科特迪瓦"
  },
  {
    ISO2: "DK",
    ISO3: "DNK",
    code: "208",
    name_EN: "Denmark",
    name_ZH: "丹麥",
    name_CN: "丹麦"
  },
  {
    ISO2: "DJ",
    ISO3: "DJI",
    code: "262",
    name_EN: "Djibouti",
    name_ZH: "吉布提",
    name_CN: "吉布提"
  },
  {
    ISO2: "DM",
    ISO3: "DMA",
    code: "212",
    name_EN: "Dominica",
    name_ZH: "多米尼克",
    name_CN: "多米尼克"
  },
  {
    ISO2: "DO",
    ISO3: "DOM",
    code: "214",
    name_EN: "Dominican Republic",
    name_ZH: "多明尼加",
    name_CN: "多米尼加"
  },
  {
    ISO2: "EC",
    ISO3: "ECU",
    code: "218",
    name_EN: "Ecuador",
    name_ZH: "厄瓜多爾",
    name_CN: "厄瓜多尔"
  },
  {
    ISO2: "EG",
    ISO3: "EGY",
    code: "818",
    name_EN: "Egypt",
    name_ZH: "埃及",
    name_CN: "埃及"
  },
  {
    ISO2: "SV",
    ISO3: "SLV",
    code: "222",
    name_EN: "El Salvador",
    name_ZH: "薩爾瓦多",
    name_CN: "萨尔瓦多"
  },
  {
    ISO2: "GQ",
    ISO3: "GNQ",
    code: "226",
    name_EN: "Equatorial Guinea",
    name_ZH: "赤道幾內亞",
    name_CN: "赤道几内亚"
  },
  {
    ISO2: "ER",
    ISO3: "ERI",
    code: "232",
    name_EN: "Eritrea",
    name_ZH: "厄立特里亞",
    name_CN: "厄立特里亚"
  },
  {
    ISO2: "EE",
    ISO3: "EST",
    code: "233",
    name_EN: "Estonia",
    name_ZH: "愛沙尼亞",
    name_CN: "爱沙尼亚"
  },
  {
    ISO2: "ET",
    ISO3: "ETH",
    code: "231",
    name_EN: "Ethiopia",
    name_ZH: "埃塞俄比亞",
    name_CN: "埃塞俄比亚"
  },
  {
    ISO2: "FK",
    ISO3: "FLK",
    code: "238",
    name_EN: "Falkland Islands (Malvinas)",
    name_ZH: "福克蘭群島（馬爾維納斯）",
    name_CN: "马尔维纳斯群岛（福克兰）"
  },
  {
    ISO2: "FO",
    ISO3: "FRO",
    code: "234",
    name_EN: "Faroe Islands",
    name_ZH: "法羅群島",
    name_CN: "法罗群岛"
  },
  {
    ISO2: "FJ",
    ISO3: "FJI",
    code: "242",
    name_EN: "Fiji",
    name_ZH: "斐濟",
    name_CN: "斐济群岛"
  },
  {
    ISO2: "FI",
    ISO3: "FIN",
    code: "246",
    name_EN: "Finland",
    name_ZH: "芬蘭",
    name_CN: "芬兰"
  },
  {
    ISO2: "FR",
    ISO3: "FRA",
    code: "250",
    name_EN: "France",
    name_ZH: "法國",
    name_CN: "法国"
  },
  {
    ISO2: "GF",
    ISO3: "GUF",
    code: "254",
    name_EN: "French Guiana",
    name_ZH: "法屬圭亞那",
    name_CN: "法属圭亚那"
  },
  {
    ISO2: "PF",
    ISO3: "PYF",
    code: "258",
    name_EN: "French Polynesia",
    name_ZH: "法屬波利尼西亞",
    name_CN: "法属波利尼西亚"
  },
  {
    ISO2: "TF",
    ISO3: "ATF",
    code: "260",
    name_EN: "French Southern Territories",
    name_ZH: "法屬南部地區",
    name_CN: "法属南部领地"
  },
  {
    ISO2: "GA",
    ISO3: "GAB",
    code: "266",
    name_EN: "Gabon",
    name_ZH: "加蓬",
    name_CN: "加蓬"
  },
  {
    ISO2: "GM",
    ISO3: "GMB",
    code: "270",
    name_EN: "Gambia",
    name_ZH: "岡比亞",
    name_CN: "冈比亚"
  },
  {
    ISO2: "GE",
    ISO3: "GEO",
    code: "268",
    name_EN: "Georgia",
    name_ZH: "格魯吉亞",
    name_CN: "格鲁吉亚"
  },
  {
    ISO2: "DE",
    ISO3: "DEU",
    code: "276",
    name_EN: "Germany",
    name_ZH: "德國",
    name_CN: "德国"
  },
  {
    ISO2: "GH",
    ISO3: "GHA",
    code: "288",
    name_EN: "Ghana",
    name_ZH: "加納",
    name_CN: "加纳"
  },
  {
    ISO2: "GI",
    ISO3: "GIB",
    code: "292",
    name_EN: "Gibraltar",
    name_ZH: "直布羅陀",
    name_CN: "直布罗陀"
  },
  {
    ISO2: "GR",
    ISO3: "GRC",
    code: "300",
    name_EN: "Greece",
    name_ZH: "希臘",
    name_CN: "希腊"
  },
  {
    ISO2: "GL",
    ISO3: "GRL",
    code: "304",
    name_EN: "Greenland",
    name_ZH: "格陵蘭",
    name_CN: "格陵兰"
  },
  {
    ISO2: "GD",
    ISO3: "GRD",
    code: "308",
    name_EN: "Grenada",
    name_ZH: "格林納達",
    name_CN: "格林纳达"
  },
  {
    ISO2: "GP",
    ISO3: "GLP",
    code: "312",
    name_EN: "Guadeloupe",
    name_ZH: "瓜德魯普島",
    name_CN: "瓜德罗普"
  },
  {
    ISO2: "GU",
    ISO3: "GUM",
    code: "316",
    name_EN: "Guam",
    name_ZH: "關島",
    name_CN: "关岛"
  },
  {
    ISO2: "GT",
    ISO3: "GTM",
    code: "320",
    name_EN: "Guatemala",
    name_ZH: "危地馬拉",
    name_CN: "危地马拉"
  },
  {
    ISO2: "GG",
    ISO3: "GGY",
    code: "831",
    name_EN: "Guernsey",
    name_ZH: "根西島",
    name_CN: "根西岛"
  },
  {
    ISO2: "GN",
    ISO3: "GIN",
    code: "324",
    name_EN: "Guinea",
    name_ZH: "幾內亞",
    name_CN: "几内亚"
  },
  {
    ISO2: "GW",
    ISO3: "GNB",
    code: "624",
    name_EN: "Guinea-Bissau",
    name_ZH: "幾內亞比紹",
    name_CN: "几内亚比绍"
  },
  {
    ISO2: "GY",
    ISO3: "GUY",
    code: "328",
    name_EN: "Guyana",
    name_ZH: "圭亞那",
    name_CN: "圭亚那"
  },
  {
    ISO2: "HT",
    ISO3: "HTI",
    code: "332",
    name_EN: "Haiti",
    name_ZH: "海地",
    name_CN: "海地"
  },
  {
    ISO2: "HM",
    ISO3: "HMD",
    code: "334",
    name_EN: "Heard Island and McDonald Islands",
    name_ZH: "赫德島和麥克唐納群島",
    name_CN: "赫德岛和麦克唐纳群岛"
  },
  {
    ISO2: "HN",
    ISO3: "HND",
    code: "340",
    name_EN: "Honduras",
    name_ZH: "宏都拉斯",
    name_CN: "洪都拉斯"
  },
  {
    ISO2: "HK",
    ISO3: "HKG",
    code: "344",
    name_EN: "Hong Kong",
    name_ZH: "香港",
    name_CN: "香港"
  },
  {
    ISO2: "HU",
    ISO3: "HUN",
    code: "348",
    name_EN: "Hungary",
    name_ZH: "匈牙利",
    name_CN: "匈牙利"
  },
  {
    ISO2: "IS",
    ISO3: "ISL",
    code: "352",
    name_EN: "Iceland",
    name_ZH: "冰島",
    name_CN: "冰岛"
  },
  {
    ISO2: "IN",
    ISO3: "IND",
    code: "356",
    name_EN: "India",
    name_ZH: "印度",
    name_CN: "印度"
  },
  {
    ISO2: "ID",
    ISO3: "IDN",
    code: "360",
    name_EN: "Indonesia",
    name_ZH: "印尼",
    name_CN: "印尼"
  },
  {
    ISO2: "IR",
    ISO3: "IRN",
    code: "364",
    name_EN: "Iran (Islamic Republic of)",
    name_ZH: "伊朗",
    name_CN: "伊朗"
  },
  {
    ISO2: "IQ",
    ISO3: "IRQ",
    code: "368",
    name_EN: "Iraq",
    name_ZH: "伊拉克",
    name_CN: "伊拉克"
  },
  {
    ISO2: "IE",
    ISO3: "IRL",
    code: "372",
    name_EN: "Ireland",
    name_ZH: "愛爾蘭",
    name_CN: "爱尔兰"
  },
  {
    ISO2: "IM",
    ISO3: "IMN",
    code: "833",
    name_EN: "Isle of Man",
    name_ZH: "萌島",
    name_CN: "马恩岛"
  },
  {
    ISO2: "IL",
    ISO3: "ISR",
    code: "376",
    name_EN: "Israel",
    name_ZH: "以色列",
    name_CN: "以色列"
  },
  {
    ISO2: "IT",
    ISO3: "ITA",
    code: "380",
    name_EN: "Italy",
    name_ZH: "意大利",
    name_CN: "意大利"
  },
  {
    ISO2: "JM",
    ISO3: "JAM",
    code: "388",
    name_EN: "Jamaica",
    name_ZH: "牙買加",
    name_CN: "牙买加"
  },
  {
    ISO2: "JP",
    ISO3: "JPN",
    code: "392",
    name_EN: "Japan",
    name_ZH: "日本",
    name_CN: "日本"
  },
  {
    ISO2: "JE",
    ISO3: "JEY",
    code: "832",
    name_EN: "Jersey",
    name_ZH: "澤西",
    name_CN: "泽西岛"
  },
  {
    ISO2: "JO",
    ISO3: "JOR",
    code: "400",
    name_EN: "Jordan",
    name_ZH: "約旦",
    name_CN: "约旦"
  },
  {
    ISO2: "KZ",
    ISO3: "KAZ",
    code: "398",
    name_EN: "Kazakhstan",
    name_ZH: "哈薩克",
    name_CN: "哈萨克斯坦"
  },
  {
    ISO2: "KE",
    ISO3: "KEN",
    code: "404",
    name_EN: "Kenya",
    name_ZH: "肯尼亞",
    name_CN: "肯尼亚"
  },
  {
    ISO2: "KI",
    ISO3: "KIR",
    code: "296",
    name_EN: "Kiribati",
    name_ZH: "基里巴斯",
    name_CN: "基里巴斯"
  },
  {
    ISO2: "KP",
    ISO3: "PRK",
    code: "408",
    name_EN: "Korea (Democratic People's Republic of)",
    name_ZH: "北韓",
    name_CN: "朝鲜"
  },
  {
    ISO2: "KR",
    ISO3: "KOR",
    code: "410",
    name_EN: "Korea (Republic of)",
    name_ZH: "韓國",
    name_CN: "韩国"
  },
  {
    ISO2: "KW",
    ISO3: "KWT",
    code: "414",
    name_EN: "Kuwait",
    name_ZH: "科威特",
    name_CN: "科威特"
  },
  {
    ISO2: "KG",
    ISO3: "KGZ",
    code: "417",
    name_EN: "Kyrgyzstan",
    name_ZH: "吉爾吉斯",
    name_CN: "吉尔吉斯斯坦"
  },
  {
    ISO2: "LA",
    ISO3: "LAO",
    code: "418",
    name_EN: "Lao People's Democratic Republic",
    name_ZH: "老挝",
    name_CN: "老挝"
  },
  {
    ISO2: "LV",
    ISO3: "LVA",
    code: "428",
    name_EN: "Latvia",
    name_ZH: "拉脫維亞",
    name_CN: "拉脱维亚"
  },
  {
    ISO2: "LB",
    ISO3: "LBN",
    code: "422",
    name_EN: "Lebanon",
    name_ZH: "黎巴嫩",
    name_CN: "黎巴嫩"
  },
  {
    ISO2: "LS",
    ISO3: "LSO",
    code: "426",
    name_EN: "Lesotho",
    name_ZH: "萊索托",
    name_CN: "莱索托"
  },
  {
    ISO2: "LR",
    ISO3: "LBR",
    code: "430",
    name_EN: "Liberia",
    name_ZH: "利比里亞",
    name_CN: "利比里亚"
  },
  {
    ISO2: "LY",
    ISO3: "LBY",
    code: "434",
    name_EN: "Libya",
    name_ZH: "利比亞",
    name_CN: "利比亚"
  },
  {
    ISO2: "LI",
    ISO3: "LIE",
    code: "438",
    name_EN: "Liechtenstein",
    name_ZH: "列支敦士登",
    name_CN: "列支敦士登"
  },
  {
    ISO2: "LT",
    ISO3: "LTU",
    code: "440",
    name_EN: "Lithuania",
    name_ZH: "立陶宛",
    name_CN: "立陶宛"
  },
  {
    ISO2: "LU",
    ISO3: "LUX",
    code: "442",
    name_EN: "Luxembourg",
    name_ZH: "盧森堡",
    name_CN: "卢森堡"
  },
  {
    ISO2: "MO",
    ISO3: "MAC",
    code: "446",
    name_EN: "Macau",
    name_ZH: "澳門",
    name_CN: "澳门"
  },
  {
    ISO2: "MG",
    ISO3: "MDG",
    code: "450",
    name_EN: "Madagascar",
    name_ZH: "馬達加斯加",
    name_CN: "马达加斯加"
  },
  {
    ISO2: "MW",
    ISO3: "MWI",
    code: "454",
    name_EN: "Malawi",
    name_ZH: "馬拉維",
    name_CN: "马拉维"
  },
  {
    ISO2: "MY",
    ISO3: "MYS",
    code: "458",
    name_EN: "Malaysia",
    name_ZH: "馬來西亞",
    name_CN: "马来西亚"
  },
  {
    ISO2: "MV",
    ISO3: "MDV",
    code: "462",
    name_EN: "Maldives",
    name_ZH: "馬爾代夫",
    name_CN: "马尔代夫"
  },
  {
    ISO2: "ML",
    ISO3: "MLI",
    code: "466",
    name_EN: "Mali",
    name_ZH: "馬里",
    name_CN: "马里"
  },
  {
    ISO2: "MT",
    ISO3: "MLT",
    code: "470",
    name_EN: "Malta",
    name_ZH: "馬爾他",
    name_CN: "马耳他"
  },
  {
    ISO2: "MH",
    ISO3: "MHL",
    code: "584",
    name_EN: "Marshall islands",
    name_ZH: "馬紹爾群島",
    name_CN: "马绍尔群岛"
  },
  {
    ISO2: "MQ",
    ISO3: "MTQ",
    code: "474",
    name_EN: "Martinique",
    name_ZH: "馬提尼克",
    name_CN: "马提尼克"
  },
  {
    ISO2: "MR",
    ISO3: "MRT",
    code: "478",
    name_EN: "Mauritania",
    name_ZH: "毛里塔尼亞",
    name_CN: "毛里塔尼亚"
  },
  {
    ISO2: "MU",
    ISO3: "MUS",
    code: "480",
    name_EN: "Mauritius",
    name_ZH: "毛里求斯",
    name_CN: "毛里求斯"
  },
  {
    ISO2: "YT",
    ISO3: "MYT",
    code: "175",
    name_EN: "Mayotte",
    name_ZH: "馬約特",
    name_CN: "马约特"
  },
  {
    ISO2: "MX",
    ISO3: "MEX",
    code: "484",
    name_EN: "Mexico",
    name_ZH: "墨西哥",
    name_CN: "墨西哥"
  },
  {
    ISO2: "FM",
    ISO3: "FSM",
    code: "583",
    name_EN: "Micronesia (Federated States of)",
    name_ZH: "密克羅尼西亞",
    name_CN: "密克罗尼西亚联邦"
  },
  {
    ISO2: "MD",
    ISO3: "MDA",
    code: "498",
    name_EN: "Moldova (Republic of)",
    name_ZH: "摩爾多瓦",
    name_CN: "摩尔多瓦"
  },
  {
    ISO2: "MC",
    ISO3: "MCO",
    code: "492",
    name_EN: "Monaco",
    name_ZH: "摩納哥",
    name_CN: "摩纳哥"
  },
  {
    ISO2: "MN",
    ISO3: "MNG",
    code: "496",
    name_EN: "Mongolia",
    name_ZH: "蒙古",
    name_CN: "蒙古"
  },
  {
    ISO2: "ME",
    ISO3: "MNE",
    code: "499",
    name_EN: "Montenegro",
    name_ZH: "黑山",
    name_CN: "黑山"
  },
  {
    ISO2: "MS",
    ISO3: "MSR",
    code: "500",
    name_EN: "Montserrat",
    name_ZH: "蒙塞拉特島",
    name_CN: "蒙塞拉特岛"
  },
  {
    ISO2: "MA",
    ISO3: "MAR",
    code: "504",
    name_EN: "Morocco",
    name_ZH: "摩洛哥",
    name_CN: "摩洛哥"
  },
  {
    ISO2: "MZ",
    ISO3: "MOZ",
    code: "508",
    name_EN: "Mozambique",
    name_ZH: "莫桑比克",
    name_CN: "莫桑比克"
  },
  {
    ISO2: "MM",
    ISO3: "MMR",
    code: "104",
    name_EN: "Myanmar",
    name_ZH: "緬甸",
    name_CN: "缅甸"
  },
  {
    ISO2: "NA",
    ISO3: "NAM",
    code: "516",
    name_EN: "Namibia",
    name_ZH: "納米比亞",
    name_CN: "纳米比亚"
  },
  {
    ISO2: "NR",
    ISO3: "NRU",
    code: "520",
    name_EN: "Nauru",
    name_ZH: "瑙魯",
    name_CN: "瑙鲁"
  },
  {
    ISO2: "NP",
    ISO3: "NPL",
    code: "524",
    name_EN: "Nepal",
    name_ZH: "尼泊爾",
    name_CN: "尼泊尔"
  },
  {
    ISO2: "NL",
    ISO3: "NLD",
    code: "528",
    name_EN: "Netherlands",
    name_ZH: "荷蘭",
    name_CN: "荷兰"
  },
  {
    ISO2: "NC",
    ISO3: "NCL",
    code: "540",
    name_EN: "New Caledonia",
    name_ZH: "新喀里多尼亞",
    name_CN: "新喀里多尼亚"
  },
  {
    ISO2: "NZ",
    ISO3: "NZL",
    code: "554",
    name_EN: "New Zealand",
    name_ZH: "新西蘭",
    name_CN: "新西兰"
  },
  {
    ISO2: "NI",
    ISO3: "NIC",
    code: "558",
    name_EN: "Nicaragua",
    name_ZH: "尼加拉瓜",
    name_CN: "尼加拉瓜"
  },
  {
    ISO2: "NE",
    ISO3: "NER",
    code: "562",
    name_EN: "Niger",
    name_ZH: "尼日爾",
    name_CN: "尼日尔"
  },
  {
    ISO2: "NG",
    ISO3: "NGA",
    code: "566",
    name_EN: "Nigeria",
    name_ZH: "尼日利亞",
    name_CN: "尼日利亚"
  },
  {
    ISO2: "NU",
    ISO3: "NIU",
    code: "570",
    name_EN: "Niue",
    name_ZH: "紐埃",
    name_CN: "纽埃"
  },
  {
    ISO2: "NF",
    ISO3: "NFK",
    code: "574",
    name_EN: "Norfolk Island",
    name_ZH: "諾福克島",
    name_CN: "诺福克岛"
  },
  {
    ISO2: "MK",
    ISO3: "MKD",
    code: "807",
    name_EN: "North Macedonia",
    name_ZH: "北馬其頓",
    name_CN: "北马其顿"
  },
  {
    ISO2: "MP",
    ISO3: "MNP",
    code: "580",
    name_EN: "Northern Mariana Islands",
    name_ZH: "北馬里亞納群島",
    name_CN: "北马里亚纳群岛"
  },
  {
    ISO2: "NO",
    ISO3: "NOR",
    code: "578",
    name_EN: "Norway",
    name_ZH: "挪威",
    name_CN: "挪威"
  },
  {
    ISO2: "OM",
    ISO3: "OMN",
    code: "512",
    name_EN: "Oman",
    name_ZH: "阿曼",
    name_CN: "阿曼"
  },
  {
    ISO2: "PK",
    ISO3: "PAK",
    code: "586",
    name_EN: "Pakistan",
    name_ZH: "巴基斯坦",
    name_CN: "巴基斯坦"
  },
  {
    ISO2: "PW",
    ISO3: "PLW",
    code: "585",
    name_EN: "Palau",
    name_ZH: "帛琉",
    name_CN: "帕劳"
  },
  {
    ISO2: "PS",
    ISO3: "PSE",
    code: "275",
    name_EN: "Palestine, State of",
    name_ZH: "巴勒斯坦",
    name_CN: "巴勒斯坦"
  },
  {
    ISO2: "PA",
    ISO3: "PAN",
    code: "591",
    name_EN: "Panama",
    name_ZH: "巴拿馬",
    name_CN: "巴拿马"
  },
  {
    ISO2: "PG",
    ISO3: "PNG",
    code: "598",
    name_EN: "Papua New Guinea",
    name_ZH: "巴布亞新幾內亞",
    name_CN: "巴布亚新几内亚"
  },
  {
    ISO2: "PY",
    ISO3: "PRY",
    code: "600",
    name_EN: "Paraguay",
    name_ZH: "巴拉圭",
    name_CN: "巴拉圭"
  },
  {
    ISO2: "PE",
    ISO3: "PER",
    code: "604",
    name_EN: "Peru",
    name_ZH: "秘魯",
    name_CN: "秘鲁"
  },
  {
    ISO2: "PH",
    ISO3: "PHL",
    code: "608",
    name_EN: "Philippines",
    name_ZH: "菲律賓",
    name_CN: "菲律宾"
  },
  {
    ISO2: "PN",
    ISO3: "PCN",
    code: "612",
    name_EN: "Pitcairn Islands",
    name_ZH: "皮特凱恩群島",
    name_CN: "皮特凯恩群岛"
  },
  {
    ISO2: "PL",
    ISO3: "POL",
    code: "616",
    name_EN: "Poland",
    name_ZH: "波蘭",
    name_CN: "波兰"
  },
  {
    ISO2: "PT",
    ISO3: "PRT",
    code: "620",
    name_EN: "Portugal",
    name_ZH: "葡萄牙",
    name_CN: "葡萄牙"
  },
  {
    ISO2: "PR",
    ISO3: "PRI",
    code: "630",
    name_EN: "Puerto Rico",
    name_ZH: "波多黎各",
    name_CN: "波多黎各"
  },
  {
    ISO2: "QA",
    ISO3: "QAT",
    code: "634",
    name_EN: "Qatar",
    name_ZH: "卡塔爾",
    name_CN: "卡塔尔"
  },
  {
    ISO2: "RO",
    ISO3: "ROU",
    code: "642",
    name_EN: "Romania",
    name_ZH: "羅馬尼亞",
    name_CN: "罗马尼亚"
  },
  {
    ISO2: "RU",
    ISO3: "RUS",
    code: "643",
    name_EN: "Russian Federation",
    name_ZH: "俄羅斯",
    name_CN: "俄罗斯"
  },
  {
    ISO2: "RW",
    ISO3: "RWA",
    code: "646",
    name_EN: "Rwanda",
    name_ZH: "盧旺達",
    name_CN: "卢旺达"
  },
  {
    ISO2: "RE",
    ISO3: "REU",
    code: "638",
    name_EN: "Réunion",
    name_ZH: "留尼汪",
    name_CN: "留尼汪"
  },
  {
    ISO2: "BL",
    ISO3: "BLM",
    code: "652",
    name_EN: "Saint Barthélemy",
    name_ZH: "聖巴托洛繆島",
    name_CN: "圣巴泰勒米岛"
  },
  {
    ISO2: "SH",
    ISO3: "SHN",
    code: "654",
    name_EN: "Saint Helena, Ascension and Tristan da Cunha",
    name_ZH: "聖赫勒拿",
    name_CN: "圣赫勒拿"
  },
  {
    ISO2: "KN",
    ISO3: "KNA",
    code: "659",
    name_EN: "Saint Kitts and Nevis",
    name_ZH: "聖基茨和尼維斯",
    name_CN: "圣基茨和尼维斯"
  },
  {
    ISO2: "LC",
    ISO3: "LCA",
    code: "662",
    name_EN: "Saint Lucia",
    name_ZH: "聖盧西亞",
    name_CN: "圣卢西亚"
  },
  {
    ISO2: "MF",
    ISO3: "MAF",
    code: "663",
    name_EN: "Saint Martin (French part)",
    name_ZH: "法屬聖馬丁",
    name_CN: "法属圣马丁"
  },
  {
    ISO2: "PM",
    ISO3: "SPM",
    code: "666",
    name_EN: "Saint Pierre and Miquelon",
    name_ZH: "聖皮埃爾島和密克隆島",
    name_CN: "圣皮埃尔和密克隆"
  },
  {
    ISO2: "VC",
    ISO3: "VCT",
    code: "670",
    name_EN: "Saint Vincent and the Grenadines",
    name_ZH: "聖文森特和格林納丁斯",
    name_CN: "圣文森特和格林纳丁斯"
  },
  {
    ISO2: "WS",
    ISO3: "WSM",
    code: "882",
    name_EN: "Samoa",
    name_ZH: "薩摩亞",
    name_CN: "萨摩亚"
  },
  {
    ISO2: "SM",
    ISO3: "SMR",
    code: "674",
    name_EN: "San Marino",
    name_ZH: "聖馬力諾",
    name_CN: "圣马力诺"
  },
  {
    ISO2: "ST",
    ISO3: "STP",
    code: "678",
    name_EN: "Sao Tome and Principe",
    name_ZH: "聖多美及普林西比",
    name_CN: "圣多美和普林西比"
  },
  {
    ISO2: "SA",
    ISO3: "SAU",
    code: "682",
    name_EN: "Saudi Arabia",
    name_ZH: "沙特阿拉伯",
    name_CN: "沙特阿拉伯"
  },
  {
    ISO2: "SN",
    ISO3: "SEN",
    code: "686",
    name_EN: "Senegal",
    name_ZH: "塞內加爾",
    name_CN: "塞内加尔"
  },
  {
    ISO2: "RS",
    ISO3: "SRB",
    code: "688",
    name_EN: "Serbia",
    name_ZH: "塞爾維亞",
    name_CN: "塞尔维亚"
  },
  {
    ISO2: "SC",
    ISO3: "SYC",
    code: "690",
    name_EN: "Seychelles",
    name_ZH: "塞舌爾",
    name_CN: "塞舌尔"
  },
  {
    ISO2: "SL",
    ISO3: "SLE",
    code: "694",
    name_EN: "Sierra Leone",
    name_ZH: "塞拉利昂",
    name_CN: "塞拉利昂"
  },
  {
    ISO2: "SG",
    ISO3: "SGP",
    code: "702",
    name_EN: "Singapore",
    name_ZH: "新加坡",
    name_CN: "新加坡"
  },
  {
    ISO2: "SX",
    ISO3: "SXM",
    code: "534",
    name_EN: "Sint Maarten (Dutch part)",
    name_ZH: "荷屬聖馬丁",
    name_CN: "荷属圣马丁"
  },
  {
    ISO2: "SK",
    ISO3: "SVK",
    code: "703",
    name_EN: "Slovakia",
    name_ZH: "斯洛伐克",
    name_CN: "斯洛伐克"
  },
  {
    ISO2: "SI",
    ISO3: "SVN",
    code: "705",
    name_EN: "Slovenia",
    name_ZH: "斯洛文尼亞",
    name_CN: "斯洛文尼亚"
  },
  {
    ISO2: "SB",
    ISO3: "SLB",
    code: "090",
    name_EN: "Solomon Islands",
    name_ZH: "所羅門群島",
    name_CN: "所罗门群岛"
  },
  {
    ISO2: "SO",
    ISO3: "SOM",
    code: "706",
    name_EN: "Somalia",
    name_ZH: "索馬里",
    name_CN: "索马里"
  },
  {
    ISO2: "ZA",
    ISO3: "ZAF",
    code: "710",
    name_EN: "South Africa",
    name_ZH: "南非",
    name_CN: "南非"
  },
  {
    ISO2: "GS",
    ISO3: "SGS",
    code: "239",
    name_EN: "South Georgia and the South Sandwich Islands",
    name_ZH: "南喬治亞島與南桑威奇群島",
    name_CN: "南乔治亚岛和南桑威奇群岛"
  },
  {
    ISO2: "SS",
    ISO3: "SSD",
    code: "728",
    name_EN: "South Sudan",
    name_ZH: "南蘇丹",
    name_CN: "南苏丹"
  },
  {
    ISO2: "ES",
    ISO3: "ESP",
    code: "724",
    name_EN: "Spain",
    name_ZH: "西班牙",
    name_CN: "西班牙"
  },
  {
    ISO2: "LK",
    ISO3: "LKA",
    code: "144",
    name_EN: "Sri Lanka",
    name_ZH: "斯里蘭卡",
    name_CN: "斯里兰卡"
  },
  {
    ISO2: "SD",
    ISO3: "SDN",
    code: "729",
    name_EN: "Sudan",
    name_ZH: "蘇丹",
    name_CN: "苏丹"
  },
  {
    ISO2: "SR",
    ISO3: "SUR",
    code: "740",
    name_EN: "Suriname",
    name_ZH: "蘇里南",
    name_CN: "苏里南"
  },
  {
    ISO2: "SJ",
    ISO3: "SJM",
    code: "744",
    name_EN: "Svalbard and Jan Mayen",
    name_ZH: "斯瓦爾巴特群島",
    name_CN: "斯瓦尔巴群岛和扬马延岛"
  },
  {
    ISO2: "SZ",
    ISO3: "SWZ",
    code: "748",
    name_EN: "Swaziland",
    name_ZH: "斯威士蘭",
    name_CN: "斯威士兰"
  },
  {
    ISO2: "SE",
    ISO3: "SWE",
    code: "752",
    name_EN: "Sweden",
    name_ZH: "瑞典",
    name_CN: "瑞典"
  },
  {
    ISO2: "CH",
    ISO3: "CHE",
    code: "756",
    name_EN: "Switzerland",
    name_ZH: "瑞士",
    name_CN: "瑞士"
  },
  {
    ISO2: "SY",
    ISO3: "SYR",
    code: "760",
    name_EN: "Syrian Arab Republic",
    name_ZH: "敘利亞",
    name_CN: "叙利亚"
  },
  {
    ISO2: "TW",
    ISO3: "TWN",
    code: "158",
    name_EN: "Taiwan",
    name_ZH: "台灣",
    name_CN: "台湾"
  },
  {
    ISO2: "TJ",
    ISO3: "TJK",
    code: "762",
    name_EN: "Tajikistan",
    name_ZH: "塔吉克",
    name_CN: "塔吉克斯坦"
  },
  {
    ISO2: "TZ",
    ISO3: "TZA",
    code: "834",
    name_EN: "Tanzania",
    name_ZH: "坦桑尼亞",
    name_CN: "坦桑尼亚"
  },
  {
    ISO2: "TH",
    ISO3: "THA",
    code: "764",
    name_EN: "Thailand",
    name_ZH: "泰國",
    name_CN: "泰国"
  },
  {
    ISO2: "TL",
    ISO3: "TLS",
    code: "626",
    name_EN: "Timor-Leste",
    name_ZH: "東帝汶",
    name_CN: "东帝汶"
  },
  {
    ISO2: "TG",
    ISO3: "TGO",
    code: "768",
    name_EN: "Togo",
    name_ZH: "多哥",
    name_CN: "多哥"
  },
  {
    ISO2: "TK",
    ISO3: "TKL",
    code: "772",
    name_EN: "Tokelau",
    name_ZH: "托克勞群島",
    name_CN: "托克劳"
  },
  {
    ISO2: "TO",
    ISO3: "TON",
    code: "776",
    name_EN: "Tonga",
    name_ZH: "湯加",
    name_CN: "汤加"
  },
  {
    ISO2: "TT",
    ISO3: "TTO",
    code: "780",
    name_EN: "Trinidad and Tobago",
    name_ZH: "千里達和多巴哥",
    name_CN: "特立尼达和多巴哥"
  },
  {
    ISO2: "TN",
    ISO3: "TUN",
    code: "788",
    name_EN: "Tunisia",
    name_ZH: "突尼斯",
    name_CN: "突尼斯"
  },
  {
    ISO2: "TR",
    ISO3: "TUR",
    code: "792",
    name_EN: "Turkey",
    name_ZH: "土耳其",
    name_CN: "土耳其"
  },
  {
    ISO2: "TM",
    ISO3: "TKM",
    code: "795",
    name_EN: "Turkmenistan",
    name_ZH: "土庫曼",
    name_CN: "土库曼斯坦"
  },
  {
    ISO2: "TC",
    ISO3: "TCA",
    code: "796",
    name_EN: "Turks and Caicos Islands",
    name_ZH: "特克斯和凱科斯群島",
    name_CN: "特克斯和凯科斯群岛"
  },
  {
    ISO2: "TV",
    ISO3: "TUV",
    code: "798",
    name_EN: "Tuvalu",
    name_ZH: "圖瓦盧",
    name_CN: "图瓦卢"
  },
  {
    ISO2: "UG",
    ISO3: "UGA",
    code: "800",
    name_EN: "Uganda",
    name_ZH: "烏干達",
    name_CN: "乌干达"
  },
  {
    ISO2: "UA",
    ISO3: "UKR",
    code: "804",
    name_EN: "Ukraine",
    name_ZH: "烏克蘭",
    name_CN: "乌克兰"
  },
  {
    ISO2: "AE",
    ISO3: "ARE",
    code: "784",
    name_EN: "United Arab Emirates",
    name_ZH: "阿聯酋",
    name_CN: "阿联酋"
  },
  {
    ISO2: "GB",
    ISO3: "GBR",
    code: "826",
    name_EN: "United Kingdom",
    name_ZH: "英國",
    name_CN: "英国"
  },
  {
    ISO2: "US",
    ISO3: "USA",
    code: "840",
    name_EN: "United States",
    name_ZH: "美國",
    name_CN: "美国"
  },
  {
    ISO2: "UM",
    ISO3: "UMI",
    code: "581",
    name_EN: "United States Minor Outlying Islands",
    name_ZH: "美國海外小島",
    name_CN: "美国本土外小岛屿"
  },
  {
    ISO2: "UY",
    ISO3: "URY",
    code: "858",
    name_EN: "Uruguay",
    name_ZH: "烏拉圭",
    name_CN: "乌拉圭"
  },
  {
    ISO2: "UZ",
    ISO3: "UZB",
    code: "860",
    name_EN: "Uzbekistan",
    name_ZH: "烏茲別克",
    name_CN: "乌兹别克斯坦"
  },
  {
    ISO2: "VU",
    ISO3: "VUT",
    code: "548",
    name_EN: "Vanuatu",
    name_ZH: "瓦努阿圖",
    name_CN: "瓦努阿图"
  },
  {
    ISO2: "VA",
    ISO3: "VAT",
    code: "336",
    name_EN: "Vatican City",
    name_ZH: "梵蒂岡",
    name_CN: "梵蒂冈"
  },
  {
    ISO2: "VE",
    ISO3: "VEN",
    code: "862",
    name_EN: "Venezuela (Bolivarian Republic of)",
    name_ZH: "委內瑞拉",
    name_CN: "委内瑞拉"
  },
  {
    ISO2: "VN",
    ISO3: "VNM",
    code: "704",
    name_EN: "Vietnam",
    name_ZH: "越南",
    name_CN: "越南"
  },
  {
    ISO2: "VG",
    ISO3: "VGB",
    code: "092",
    name_EN: "Virgin Islands (British)",
    name_ZH: "英屬處女群島",
    name_CN: "英属维尔京群岛"
  },
  {
    ISO2: "VI",
    ISO3: "VIR",
    code: "850",
    name_EN: "Virgin Islands (U.S.)",
    name_ZH: "美屬處女群島",
    name_CN: "美属维尔京群岛"
  },
  {
    ISO2: "WF",
    ISO3: "WLF",
    code: "876",
    name_EN: "Wallis and Futuna",
    name_ZH: "瓦利斯群島和富圖納群島",
    name_CN: "瓦利斯和富图纳"
  },
  {
    ISO2: "EH",
    ISO3: "ESH",
    code: "732",
    name_EN: "Western Sahara",
    name_ZH: "西撒哈拉",
    name_CN: "西撒哈拉"
  },
  {
    ISO2: "YE",
    ISO3: "YEM",
    code: "887",
    name_EN: "Yemen",
    name_ZH: "也門",
    name_CN: "也门"
  },
  {
    ISO2: "ZM",
    ISO3: "ZMB",
    code: "894",
    name_EN: "Zambia",
    name_ZH: "贊比亞",
    name_CN: "赞比亚"
  },
  {
    ISO2: "ZW",
    ISO3: "ZWE",
    code: "716",
    name_EN: "Zimbabwe",
    name_ZH: "津巴布韋",
    name_CN: "津巴布韦"
  },
  {
    ISO2: "AX",
    ISO3: "ALA",
    code: "248",
    name_EN: "Åaland Islands",
    name_ZH: "亞蘭群島",
    name_CN: "奥兰群岛"
  }
]

export { SHOPIFY_CATEGORIES, USER_STATUS_MAP, COUNTRY_MAP, TABLE_SIZE_OPTIONS, SUPPLIER_STATUS_MAP, SUPPLIER_REGION_MAP, SUPPLIER_TYPE_MAP, PURCHASEORDER_STATUS_MAP, BRAND_STATUS_MAP, BRAND_RANKS, BANKS, COUNTRIES, SPECS_STATUS_MAP, LOCATION_STATUS_MAP, LOCATION_TYPES_MAP,Tr_MAP_LOCATION_STATUS,Tr_MAP_LOCATION_TYPES, WAREHOUSE_TRANSFER_STATUS_MAP, WAREHOUSE_TRANSFER_STATUS_BADGE_MAP, STOCK_TAKE_STATUS_MAP, STOCK_TAKE_STATUS_BADGE_MAP };