import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UpdatePurchaseOrdersComponent } from './update-purchase-orders.component';

const routes: Routes = [
  { path: '', component: UpdatePurchaseOrdersComponent }, 
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UpdatePurchaseOrdersRoutingModule { }