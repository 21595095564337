import { Injectable } from '@angular/core';
import {Product} from '../models/product';
import { WebService } from './web.service';
import Order from '../models/order';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { Role } from '../models/role';
import { PermissionConfig } from '../models/permission-config';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  user: any;
  roles: Role[] = [];

  constructor(private router: Router, private permissionService: PermissionService) {
    this.loadUserFromLocalStorage();
  }

  saveLogin(user: any) {
    if (user != null) {
      const userObj = {
        id: user.id,
        username: user.username,
        roleId: user.roleId,
        role_name: user.role_name,
        department: user.department,
        location: user.location,
      };
  
      // if (user.roleId) {
      //   const role = this.roles.find(role => role.id === user.roleId);
      //   userObj['role'] = role?.role_name;
      // } else {
      //   console.log("User role is empty.");
      // }
  
      console.log('user:',userObj);
      localStorage.setItem('user', JSON.stringify(userObj));
      this.user = userObj;
      this.permissionService.setUser(userObj);
    }
  }

  setRoles(roles: Role[]) {
    this.roles = roles;
  }

  logout() {
    localStorage.removeItem('user');
    this.user = null;
    this.router.navigate(['/login']);
  }

  loadUserFromLocalStorage() {
    const user = localStorage.getItem('user');
    if (user) {
      this.user = JSON.parse(user);
    }
  }

  getCurrentUser() {
    return this.user;
  }
}
