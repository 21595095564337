import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdatePurchaseOrdersRoutingModule } from './update-purchase-orders-routing.module';
import { UpdatePurchaseOrdersComponent } from './update-purchase-orders.component'

//ng-zorro-antd imports
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    UpdatePurchaseOrdersRoutingModule,
    //ng-zorro-antd imports
    NzTableModule,
    NzInputModule,
    NzPageHeaderModule,
    NzLayoutModule,
    NzIconModule,
    NzSpaceModule,
    NzModalModule,
    NzFormModule,
    NzDropDownModule,
    NzButtonModule,
    NzMessageModule,
    NzCardModule,
    NzUploadModule,
    NzCheckboxModule,
    NzDividerModule,
    NzSelectModule,
    NzBreadCrumbModule,
    NzDatePickerModule,
    NzInputModule,
    NzInputNumberModule,
    NzSpinModule
  ],
  declarations: [
    UpdatePurchaseOrdersComponent
  ],
  exports: [
    UpdatePurchaseOrdersComponent
  ]
})
export class UpdatePurchaseOrdersModule{

}