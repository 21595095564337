<nz-page-header class="site-page-header" [nzTitle]="'商品列表'"
                style="height: 44px; padding-bottom: 54px; padding-top: 0; padding-left: 4px; padding-right: 0px;">
  <nz-page-header-extra>
    <button nz-button nzType="primary" style="margin-right: 16px; background-color: #52c41a; border-color: #52c41a;" (click)="syncToShopify()">同步Shopify產品</button>
    <button nz-button nzType="primary" nzMatchRouter routerLink="/products/import"
            style="margin-right: 16px;">導入產品
    </button>
    <button nz-button nzType="primary" nzMatchRouter routerLink="/products/add">新增產品</button>
  </nz-page-header-extra>
</nz-page-header>


<nz-card>
  <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24 }">
    <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
      <p>產品名稱：</p>
      <input style="width: 300px" name="product_name" nz-input placeholder="" [(ngModel)]="searchParam.product_name"
             [nzAutocomplete]="product_name"/>
      <nz-autocomplete [nzDataSource]="productNameFilterOptions" #product_name></nz-autocomplete>
    </div>

    <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
      <p>條碼：</p>
      <input style="width: 300px" name="barcode" nz-input placeholder="" [(ngModel)]="searchParam.barcode"
             [nzAutocomplete]="barcode"/>
      <nz-autocomplete [nzDataSource]="barcodesFilterOptions" #barcode></nz-autocomplete>
    </div>

    <!-- <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
      <div nz-row>
        <p nz-col>狀態：</p>
        <nz-select nz-col style="width: 300px" nzShowSearch nzAllowClear [(ngModel)]="searchParam.status"
          (ngModelChange)="onChangeSearchParams($event)">
          <nz-option *ngFor="let status of statusFilterOptions" [nzLabel]="status" [nzValue]="status"></nz-option>
        </nz-select>
      </div>
    </div> -->

    <!--div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
      <div nz-row>
        <p nz-col>品牌：</p>
        <div nz-col>
          <input style="width: 300px"name="brand" nz-input placeholder="" [(ngModel)]="searchParam.brand_name" (ngModelChange)="onChangeSearchParams($event)" [nzAutocomplete]="brand_name"/>
          <nz-autocomplete [nzDataSource]="brandFilterOptions" #brand_name></nz-autocomplete>
        </div>
      </div>
    </div> -->

    <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
      <p>品牌：</p>
      <nz-select style="width: 300px" name="brand" nz-input placeholder="请选择品牌"
                 [(ngModel)]="searchParam.brand_list"
                 [nzMode]="'multiple'">
        <nz-option *ngFor="let option of brandListFilterOptions" [nzLabel]="option" [nzValue]="option"></nz-option>
      </nz-select>
    </div>

    <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
      <p>產地：</p>
      <nz-select style="width: 300px" name="brand" nz-input placeholder="请选择產地" [(ngModel)]="searchParam.country"
                 [nzMode]="'multiple'">
        <nz-option *ngFor="let coun of countryFilterOptions" [nzLabel]="coun" [nzValue]="coun"></nz-option>
      </nz-select>
    </div>

    <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
      <p>一級分類：</p>
      <nz-select style="width: 300px" name="first" nz-input placeholder="请选择一級分類"
                 [(ngModel)]="searchParam.first_level_name"
                 [nzMode]="'multiple'">
        <nz-option *ngFor="let first of firstLevelFilterOptions" [nzLabel]="first" [nzValue]="first"></nz-option>
      </nz-select>
    </div>

    <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
      <p>二級分類：</p>
      <nz-select style="width: 300px" name="second" nz-input placeholder="请选择二級分類"
                 [(ngModel)]="searchParam.second_level_name"
                 [nzMode]="'multiple'">
        <nz-option *ngFor="let second of secondLevelFilterOptions" [nzLabel]="second"
                   [nzValue]="second"></nz-option>
      </nz-select>
    </div>

    <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
      <p>三級分類：</p>
      <nz-select style="width: 300px" name="third" nz-input placeholder="请选择三級分類"
                 [(ngModel)]="searchParam.third_level_name"
                 [nzMode]="'multiple'">
        <nz-option *ngFor="let third of thirdLevelFilterOptions" [nzLabel]="third" [nzValue]="third"></nz-option>
      </nz-select>
    </div>
  </div>
  <div nz-row style="margin-top: 16px;">
    <div style="text-align: right" nz-col nzSpan="22">
      <button nz-button nzType="default" (click)="searchProducts()">
        <span nz-icon nzType="search"></span>
        搜索
      </button>
    </div>
  </div>
</nz-card>

<nz-card style="margin-top: 16px;">
  <nz-page-header class="site-page-header" nzTitle="商品列表" style="padding-bottom: 0px;">
    <nz-page-header-extra style="display: flex; justify-content: center; align-items: center;">
      <nz-space>
        <i *nzSpaceItem class="hand-model" nz-icon nz-tooltip nzTheme="outline" nzTooltipTitle="重新加載"
           nzType="reload"
           (click)="reload()" style="margin-left: 12px; font-size: 16px;"></i>
        <i *nzSpaceItem class="hand-model" nz-icon nz-tooltip nzTheme="outline" nzTooltipTitle="密度"
           nzType="column-height" style="margin-left: 12px; font-size: 16px;" nz-dropdown nzTrigger="click"
           [nzDropdownMenu]="tableSizeMenu"></i>
        <i *nzSpaceItem class="hand-model" nz-icon nz-popover nz-tooltip nzPopoverPlacement="bottomRight"
           nzPopoverTrigger="click" nzTheme="outline" nzTooltipTitle="列設置" nzType="setting"
           style="margin-left: 12px; font-size: 16px;" [(nzPopoverVisible)]="settingsVisible"
           [nzPopoverContent]="contentTemplate">
        </i>
      </nz-space>
    </nz-page-header-extra>
  </nz-page-header>
  <nz-spin [nzSpinning]="isSpinning">
    <nz-table #fixedTable nzShowSizeChanger [nzData]="ProductListFiltered"
              [nzSize]="tableSize" [nzTotal]="ProductList.length" [nzPageSizeOptions]="[10, 20, 30, 50, 100, 200, 500]"
              (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
      <thead>
      <tr>
        <th nzLeft [(nzChecked)]="checked" [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"></th>
        <ng-container *ngFor="let row of Product_rows">
          <th *ngIf="row.show" [nzSortOrder]="row.sortOrder" [nzSortFn]="row.sortFn"
              [nzSortDirections]="row.sortDirections" [nzLeft]="row.key === 'name'">
            {{ row.name }}
          </th>
        </ng-container>
        <th>操作</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let product of fixedTable.data">
        <td [nzChecked]="setOfCheckedId.has(product.id)" (nzCheckedChange)="onItemChecked(product.id, $event)"></td>
        <ng-container *ngFor="let row of Product_rows">
          <td *ngIf="row.show && !row.custom">{{ product[row.key] }}</td>
          <td *ngIf="row.show && row.key === 'name'" nzLeft>
            <a (click)="editProduct(product.id)" style="color: black;">{{ product.name }}</a>
          </td>
          <td *ngIf="row.show && row.key==='status'">
            <nz-badge [nzText]="product[row.key]" [nzStatus]="product[row.key] === '有效' ? 'success' : 'error'"/>
          </td>
        </ng-container>
        <td>
          <nz-space>
<!--            <a *nzSpaceItem (click)="viewProductDetail(product.id)">查看</a>-->
<!--            <a *nzSpaceItem (click)="editProduct(product.id)">修改</a>-->
<!--            <a *nzSpaceItem (click)="confirmDelete(product.id)">刪除</a>-->
            <a [routerLink]="['/products/detail', product.id]">查看</a>
            <nz-divider nzType="vertical" ></nz-divider>
            <a [routerLink]="['/products/update', product.id]">修改</a>
            <nz-divider nzType="vertical" ></nz-divider>
            <a *nzSpaceItem (click)="confirmDelete(product.id)">刪除</a>
          </nz-space>
        </td>
      </tr>
      </tbody>

    </nz-table>
  </nz-spin>
</nz-card>

<ng-template #contentTemplate>
  <div style="max-height: 200px; overflow-y: auto;">
    <ul cdkDropList (cdkDropListDropped)="dropTableConfig($event)" style="padding-left: 0; margin: 0;">
      <li *ngFor="let item of Product_rows" class="settings" cdkDrag style="padding: 4px 0 8px 0">
        <div>
          <i class="m-r-8 hand-model-move" cdkDragHandle nz-icon nzTheme="outline" nzType="drag"></i>
          <label nz-checkbox [(nzChecked)]="item.show" (nzCheckedChange)="changeSignalCheck($event, item)">
            {{ item.name }}
          </label>
        </div>
      </li>
    </ul>
  </div>
</ng-template>

<nz-dropdown-menu #tableSizeMenu="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <li nz-menu-item *ngFor="let option of TABLE_SIZE_OPTIONS" (click)="tableSize = option.value"
        [nzSelected]="option.selected">{{ option.sizeName }}
    </li>
  </ul>
</nz-dropdown-menu>

<nz-footer *ngIf="setOfCheckedId.size > 0">
  <div class="footer-bar flex">
    <div class="flex-1 footer-text">
      已選擇 {{ setOfCheckedId.size }} 個商品
    </div>
    <div>
      <button *ngIf="setOfCheckedId.size > 1" style="margin-left: 12px;" (click)="confirmBatchDelete()" nz-button
              class="mr10">批量刪除
      </button>
    </div>
  </div>
</nz-footer>

<nz-modal [(nzVisible)]="isDeleteModalVisible" nzTitle="確認刪除"
          (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <p>確定要刪除這個商品嗎？</p>
</nz-modal>

<nz-modal [(nzVisible)]="isBatchDeleteModalVisible" nzTitle="確認批量刪除"
          (nzOnCancel)="handleCancel()" (nzOnOk)="handleBatchOk()">
  <p>確定要刪除這些用戶嗎？</p>
</nz-modal>
