//local imports
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ObjectUnsubscribedError, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray, } from '@angular/forms';
import { StateService } from 'src/app/services/state.service';
import { WebService } from 'src/app/services/web.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { PurchaseOrderService } from 'src/app/services/purchase_order.service';
import { CacheService } from 'src/app/services/cache.service';

//ng-zorro imports
import { NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzInputModule } from 'ng-zorro-antd/input';
import { PurchaseOrder, Supplier, User, Product } from 'src/app/models/purchaseOrder';
import { RowSettings } from 'src/app/models/row-setting';
import { formatISODateToYYYYMMDD, isNotEmpty } from 'src/app/utils/utils';
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { ViewEncapsulation } from '@angular/core';
import { NzTableSize } from 'ng-zorro-antd/table';
import { NzUploadChangeParam, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ProductImage } from 'src/app/models/productImage';
import { ProductSpecification } from 'src/app/models/productSpecification';
import { PurchaseOrderProduct, PurchaseOrderFile } from 'src/app/models/purchaseOrder';
import { PermissionService } from 'src/app/services/permission.service';


const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

@Component({
  selector: 'app-update-purchaseOrders',
  templateUrl: './update-purchase-orders.component.html',
  styleUrls: ['./update-purchase-orders.component.scss']
})
export class UpdatePurchaseOrdersComponent implements OnInit {
  updatePurchaseOrderForm: FormGroup;
  invoice_url: string = '';
  file_urls: string[] = [];
  InvoiceFileList: NzUploadFile[] = [];
  OtherFilesList: NzUploadFile[] = [];

  purchaseOrderId: number | undefined;
  current_user: User = this.StateService.user;
  fileList: NzUploadFile[] = [];
  purchaseOrder: PurchaseOrder = {} as PurchaseOrder;
  isLoading = true;
  editCache: { [key: number]: any } = {};

  isSpinning = false;
  searchBarcode: string = '';
  searchTerm: string = '';
  listOfProduct: PurchaseOrderProduct[] = [];
  listOfFile: PurchaseOrderFile[] = [];
  buyerList: User[] = [];
  productList: Product[] = [];
  filteredProductList: Product[] = [];
  supplierList: Supplier[] = [];

  previewImage: string | undefined = '';
  previewVisible = false;

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    console.log("preview file", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  handleDownload(file: NzUploadFile): void {
    console.log("download file", file);
    if (file.url) {
      window.open(file.url, '_blank');
    } else {
      console.error('文件没有 URL,无法下载');
    }
  }

  constructor(
    public cacheService: CacheService,
    private message: NzMessageService,
    private StateService: StateService,
    private router: Router,
    private purchaseOrderService: PurchaseOrderService,
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private webService: WebService,
    public permission: PermissionService
  ) {
    this.permission.check(this.permission.isProductSectionAllowed);

    this.updatePurchaseOrderForm = this.fb.group({
      supplier_id: [null, [Validators.required]],
      buyer_id: [null, [Validators.required]],
      currency: ["HKD", [Validators.required]],
      purchase_date: [null, [Validators.required]],
      expected_arrival_date: [],
      purchaseOrderProducts: this.fb.array([]),
      remarks: [null],
    });
  }

  ngOnInit(): void {
    this.isSpinning = true;
    this.purchaseOrderId = +(this.route.snapshot.paramMap.get('id') ?? '');
    this.StateService.user = this.StateService.getCurrentUser();

    if (!this.StateService.user) {
      console.error('User is not logged in');
      this.router.navigate(['/purchaseOrders']);
    }

    this.cacheService.allSuppliers().then((res) => {
      this.supplierList = res;
    });

    this.cacheService.allUsers().then((res) => {
      this.buyerList = res;
    });

    this.cacheService.allProducts().then((res) => {
      this.productList = res;
      this.filteredProductList = res;
    });

    this.purchaseOrderService.getPurchaseOrder(this.purchaseOrderId).then((res) => {
      console.log("res",res);
      if (!res) {
        this.router.navigate(['/purchase-orders']);
        return;
      }
      this.purchaseOrder = res;
      this.updatePurchaseOrderForm.controls['supplier_id'].setValue(res.supplier.id);
      this.updatePurchaseOrderForm.controls['buyer_id'].setValue(res.buyer.id);
      this.updatePurchaseOrderForm.controls['currency'].setValue(res.currency);
      this.updatePurchaseOrderForm.controls['purchase_date'].setValue(new Date(res.purchase_date));
      if (res.expected_arrival_date)
        this.updatePurchaseOrderForm.controls['expected_arrival_date'].setValue(res.expected_arrival_date);
      this.updatePurchaseOrderForm.controls['remarks'].setValue(res.remarks);
      if (res.invoice_url) {
        this.invoice_url = res.invoice_url;
        this.InvoiceFileList = [{
          uid: '-1',
          name: 'invoice',
          status: 'done',
          url: res.invoice_url,
        }];
      }
      if (res.purchaseOrderFiles && res.purchaseOrderFiles.length > 0) {
        this.purchaseOrder.purchaseOrderFiles.forEach((file) => {
          this.file_urls.push(file.file_url);
          this.OtherFilesList.push({
            uid: file.id.toString(),
            name: file.file_url?.split('/')?.pop() ?? "file",
            status: 'done',
            url: file.file_url,
          });
        });
      }
      this.OtherFilesList = [...this.OtherFilesList];
      this.listOfProduct = res.purchaseOrderProducts;
      this.listOfProduct.forEach((poProduct) => {
        this.products.push(
          this.fb.group({
            product_id: [poProduct.product.id, [Validators.required]],
            cost: [poProduct.cost, [Validators.required]],
            selling_price: [poProduct.selling_price, [Validators.required]],
            market_price: [poProduct.market_price, [Validators.required]],
            quantity: [poProduct.quantity, [Validators.required]],
            best_before_date: [poProduct.best_before_date],
            promotion: [poProduct.promotion],
            purchase_reason: [poProduct.purchase_reason],
          })
        );
      });
      this.isSpinning = false;
    });
  }

  get products(): FormArray {
    return this.updatePurchaseOrderForm.controls["purchaseOrderProducts"] as FormArray;
  }

  addRow(): void {
    this.products.push(
      this.fb.group({
        product_id: [null, [Validators.required]],
        cost: [null, [Validators.required]],
        selling_price: [null, [Validators.required]],
        market_price: [null, [Validators.required]],
        quantity: [null, [Validators.required]],
        best_before_date: [null],
        promotion: [null],
        purchase_reason: [null],
    }));
  }

  removeRow(index: number): void {
    this.products.removeAt(index);
  }

  productFilterOption = (input: string, option: any): boolean => {
    return option.nzLabel.toLowerCase().includes(input.toLowerCase()) ||
      this.productList.find(product => product.id === option.nzValue)?.barcode.toLowerCase().includes(input.toLowerCase());
  };

  filterProducts(): void {
    if (!this.searchTerm) {
      this.filteredProductList = this.productList;
    } else {
      this.filteredProductList = this.productList.filter(product =>
        product.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        product.barcode.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  uploadInvoice = (item: NzUploadXHRArgs): Subscription => {
    const formData = new FormData();
    formData.append('file', item.file as any);

    const uploadUrl = 'http://localhost:3001/purchase-orders/invoice';

    return this.http.post(uploadUrl, formData).subscribe({
      next: (event) => {
          item.onSuccess!(event, item.file, event);
      },
      error: (error) => {
        console.error('Upload error:', error);
        item.onError!(error, item.file);
      }
    });
  }

  uploadFiles = (item: NzUploadXHRArgs): Subscription => {
    const formData = new FormData();
    formData.append('file', item.file as any);

    const uploadUrl = 'http://localhost:3001/purchase-orders/others';

    return this.http.post(uploadUrl, formData).subscribe({
      next: (event) => {
          item.onSuccess!(event, item.file, event);
      },
      error: (error) => {
        console.error('Upload error:', error);
        item.onError!(error, item.file);
      }
    });
  }

  handleinvoiceChange({ file, fileList }: NzUploadChangeParam): void {
    const status = file.status;
    if (status === 'removed')
      this.invoice_url = '';
    if (status === 'done') {
      this.message.success(`${file.name} file uploaded successfully.`);
      this.invoice_url = file.response.location;
    } else if (status === 'error') {
      this.message.error(`${file.name} file upload failed.`);
    }
  }

  handleFileChange({ file, fileList }: NzUploadChangeParam): void {
    console.log("file", file);
    console.log("fileList", fileList);
    const status = file.status;
    if (status === 'removed'){
      // previously upload files
      this.file_urls = this.file_urls.filter((url) => url !== file?.url);
      // newly uploaded files
      this.file_urls = this.file_urls.filter((url) => url !== file?.response?.location);
    }
    if (status === 'done') {
      this.message.success(`${file.name} file uploaded successfully.`);
      this.file_urls.push(file.response.location);
    } else if (status === 'error') {
      this.message.error(`${file.name} file upload failed.`);
    }
  }

  async onSubmit() {
    console.log("form", this.updatePurchaseOrderForm);

    if (this.updatePurchaseOrderForm.status === 'INVALID') {
      Object.values(this.updatePurchaseOrderForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      Object.values(this.products.controls).forEach((product: any) => {
        if (product.invalid) {
          Object.values(product.controls).forEach((control: any) => {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          });
        }
      });
      return;
    }
    // send update request
    this.isSpinning = true;
    const currentUser = this.StateService.user;
    if (!currentUser) {
      console.error('User is not logged in');
      this.router.navigate(['/purchaseOrders']);
    }
    if (!this.purchaseOrderId) {
      console.error('Purchase Order ID is not set');
      this.router.navigate(['/purchaseOrders']);
      return;
    }

    const purchaseOrderData = this.updatePurchaseOrderForm.value;
    purchaseOrderData.invoice_url = this.invoice_url;
    purchaseOrderData.purchaseOrderFiles = this.file_urls;

    console.log("request",purchaseOrderData);
    this.webService.updatePurchaseOrders(this.purchaseOrderId, currentUser.id, purchaseOrderData).then(
      response => {
        this.isSpinning = false;
        this.message.success('PurcahseOrder updated successfully');
        this.router.navigate(['/purchase-orders']);
      }
    ).catch(
      error => {
        this.message.error('Failed to update Product');
      }
    );
  }

  cancel() {
    this.router.navigate(['/purchase-orders']);
  }
}