// src/app/auth.service.ts
import { Injectable } from '@angular/core';
import { WebService } from './web.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUser: any;

  constructor(private webService: WebService) {
    this.loadUser();
  }

  loadUser() {
    const user = localStorage.getItem('user');
    if (user) {
      this.currentUser = JSON.parse(user);
    }
  }

  getUser() {
    return this.currentUser;
  }

  addUserActivity(entity_type: string, entity_id: number, action_type: string, details:string='') {
    this.webService.addUserActivity({
      user_id: this.currentUser.id,
      entity_id: entity_id,
      entity_type: entity_type,
      action_type: action_type,
      details: details,
    }).catch(error => {
      console.error('Failed to record user activity', error);
    });
  }
}