// src/app/auth.service.ts
import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { PermissionConfig } from '../models/permission-config';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private user: any;

  isCostPermissionAllowed: boolean = false;
  isAnalyticSectionAllowed: boolean = false;
  isProductSectionAllowed: boolean = false;
  isPurchaseOrderSectionAllowed: boolean = false;
  isEditPurchaseOrderAllowed: boolean = false;
  isPaymentPurchaseOrderAllowed: boolean = false;
  isInventorySectionAllowed: boolean = false;
  isWarehouseProductSearchAllowed: boolean = false;
  isUserSectionAllowed: boolean = false;
  isLocationSectionAllowed: boolean = false;
  isEditLocationSectionAllowed: boolean = false;
  isFranchiseSectionAllowed: boolean = false;
  isOperationSectionAllowed: boolean = false;

  costPermissionConfig: PermissionConfig = {};
  operationPermissionConfig: PermissionConfig = {};
  productSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['採購']
  };
  purchaseOrderSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['採購']
  };
  editPurchaseOrderPermissionConfig: PermissionConfig = {
    allowedDepartments: ['採購主管']
  };
  paymentPurchaseOrderPermissionConfig: PermissionConfig = {};
  inventorySectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['倉庫']
  };
  warehouseProductSearchSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['倉庫']
  };
  userSectionPermissionConfig: PermissionConfig = {};
  analyticSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['採購'],
    allowedRoles: ['倉庫主管']
  };
  locationSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['採購', '倉庫']
  };
  editLocationSectionPermissionConfig: PermissionConfig = {};
  franchiseSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['加盟'],
    allowedRoles: ['加盟商']
  };

  constructor(private router: Router) {
  }

  setUser(user: any) {
    this.user = user;
    this.loadPermissions();
  }

  getUser() {
    return this.user;
  }

  loadPermissions() {
    this.isCostPermissionAllowed = this.isAllowed(this.costPermissionConfig);
    this.isProductSectionAllowed = this.isAllowed(this.productSectionPermissionConfig);
    this.isPurchaseOrderSectionAllowed = this.isAllowed(this.purchaseOrderSectionPermissionConfig);
    this.isEditPurchaseOrderAllowed = this.isAllowed(this.editPurchaseOrderPermissionConfig);
    this.isPaymentPurchaseOrderAllowed = this.isAllowed(this.paymentPurchaseOrderPermissionConfig);
    this.isInventorySectionAllowed = this.isAllowed(this.inventorySectionPermissionConfig);
    this.isWarehouseProductSearchAllowed = this.isAllowed(this.warehouseProductSearchSectionPermissionConfig);
    this.isAnalyticSectionAllowed = this.isAllowed(this.analyticSectionPermissionConfig);
    this.isUserSectionAllowed = this.isAllowed(this.userSectionPermissionConfig);
    this.isLocationSectionAllowed = this.isAllowed(this.locationSectionPermissionConfig);
    this.isEditLocationSectionAllowed = this.isAllowed(this.editLocationSectionPermissionConfig);
    this.isFranchiseSectionAllowed = this.isAllowed(this.franchiseSectionPermissionConfig);
    this.isOperationSectionAllowed = this.isAllowed(this.operationPermissionConfig);
  }

  isAllowed(...permissions: PermissionConfig[]): boolean {
    return permissions.some(permission => {
      const isRoleAllowed =
        ((permission.allowedRoles !== undefined) &&
        permission.allowedRoles.includes(this.user.role_name)) ||
        this.user.role_name === '管理員';

      const isDepartmentAllowed =
        (permission.allowedDepartments !== undefined) &&
        permission.allowedDepartments.includes(this.user.department);

      return isRoleAllowed || isDepartmentAllowed;
    });
  }

  check(isAllowed: boolean) {
    if (!isAllowed) {
      this.router.navigate(['/forbidden']);
    }
  }
}
