//local imports
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { StateService } from 'src/app/services/state.service';
import { WebService } from 'src/app/services/web.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { TABLE_SIZE_OPTIONS, PURCHASEORDER_STATUS_MAP, COUNTRY_MAP } from 'src/app/utils/constants';

//ng-zorro imports
import { NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Product, User, Brand, Category, Supplier } from 'src/app/models/product';
import { RowSettings } from 'src/app/models/row-setting';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NzTableSize } from 'ng-zorro-antd/table';
import { stat } from 'fs';
import { count } from 'console';
import { UserService } from 'src/app/services/user.service';
import { CacheService } from 'src/app/services/cache.service';
import { PermissionService } from 'src/app/services/permission.service';
//define data structres
interface SearchParam {
  product_name: string,
  barcode: string,
  status: string,
  brand_name: string,
  country: string[],
  brand_list: string[]
  first_level_name: string[],
  second_level_name: string[],
  third_level_name: string[],
}

//html routing
@Component({
  selector: 'app-products-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})


//component class
export class ProductsComponent implements OnInit {
  selectedProduct: Product | undefined;
  isDeleteModalVisible = false;
  isBatchDeleteModalVisible = false;
  disableForm: FormGroup;

  roleId: number | undefined;
  userId: number | undefined;
  ProductList: Product[] = [];
  ProductDetail: Product[] = [];
  ProductListFiltered: Product[] = [];
  isSpinning = true;
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly Product[] = [];
  setOfCheckedId = new Set<number>();

  searchParam: SearchParam = {
    product_name: '',
    barcode: '',
    status: '',
    brand_name: '',
    country: [],
    brand_list: [],
    first_level_name: [],
    second_level_name: [],
    third_level_name: [],
  };
  // for filtering
  totalCost = 0;
  productNames: Set<string> = new Set<string>();
  barcodes: Set<string> = new Set<string>();
  statuses: Set<string> = new Set<string>();
  brands: Set<string> = new Set<string>();
  brandsList: Set<string> = new Set<string>();
  countries: Set<string> = new Set<string>();
  first_level: Set<string> = new Set<string>();
  second_level: Set<string> = new Set<string>();
  third_level: Set<string> = new Set<string>();
  // listOfOption: Array<{ value: string; label: string }> = [];
  // listOfSelectedValue = [];

  productNameFilterOptions: string[] = [];
  barcodesFilterOptions: string[] = [];
  statusFilterOptions: string[] = [];
  brandFilterOptions: string[] = [];
  countryFilterOptions: string[] = [];
  brandListFilterOptions: string[] = [];
  firstLevelFilterOptions: string[] = [];
  secondLevelFilterOptions: string[] = [];
  thirdLevelFilterOptions: string[] = [];
  settingsVisible = false;
  isVisible = false; //modal visibility for addUser()
  editIsVisible = false; //modal visibility for editUser()
  resetIsVisible = false; //modal visibility for resetUserPassword()

  selectedIndex: number = 0;
  tableSize: NzTableSize = 'middle';
  TABLE_SIZE_OPTIONS = TABLE_SIZE_OPTIONS;

  selectedStatus: string = '';
  reason: string = '';
  isPopoverVisible: boolean = false;
  currentPurchaseOrderId: number | null = null;

  //table settings
  Product_default_rows: RowSettings[] = [
    <RowSettings>{
      name: "編號",
      key: "id",
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product,) => a.id - b.id,
    }, <RowSettings>{
      name: "產品名稱",
      key: "name",
      show: true,
      custom: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.name.localeCompare(b.name) ?? false,
    }, <RowSettings>{
      name: "產品簡介",
      key: "description",
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product,) => a.description.localeCompare(b.description) ?? false,
    }, <RowSettings>{
      name: "條碼",
      key: "barcode",
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product,) => a.barcode.localeCompare(b.barcode) ?? false,
    }, <RowSettings>{
      name: "零售價格",
      key: "price",
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product,) => a.price - b.price,
    }, <RowSettings>{
      name: "比較價格",
      key: "compare_at_price",
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product,) => a.compare_at_price - b.compare_at_price,
    }, <RowSettings>{
      name: "品牌",
      key: "brand_name",
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.brand_name.localeCompare(b.brand_name) ?? false,
    }, <RowSettings>{
      name: "一級分類",
      key: "first_level_name",
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.category_id.first_level_name.localeCompare(b.category_id.first_level_name) ?? false,
    }, <RowSettings>{
      name: "二級分類",
      key: "second_level_name",
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.category_id.second_level_name.localeCompare(b.category_id.second_level_name) ?? false,
    }, <RowSettings>{
      name: "三級分類",
      key: "third_level_name",
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.category_id.third_level_name.localeCompare(b.category_id.third_level_name) ?? false,
    }, <RowSettings>{
      name: "產地",
      key: "country_name",
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.country_name.localeCompare(b.country_name) ?? false,
    }, <RowSettings>{
      name: "創建時間",
      key: "created_at",
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.created_at.localeCompare(b.created_at) ?? false,
    }, <RowSettings>{
      name: "採購員",
      key: "buyer_id",
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.buyer_id.toString().localeCompare(b.buyer_id.toString()) ?? false,
    }, <RowSettings>{
      name: "狀態",
      key: "status",
      show: false,
      custom: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      // sortFn: (a: Product, b: Product,) => a.status.localeCompare(b.status) ?? false,
    },

  ]
  Product_rows = this.Product_default_rows;

  constructor(
    private stateService: StateService,
    private userService: UserService,
    private router: Router,
    private webService: WebService,
    private message: NzMessageService,
    private modalService: NzModalService,
    private cacheService: CacheService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    public permission: PermissionService
  ) {
    this.permission.check(this.permission.isProductSectionAllowed);
    this.disableForm = this.fb.group({
        id: [''],
        status: ['deleted']});
  }

  ngOnInit(): void {
    if (history.state.reload) {
      this.reload();
    }
    const user = localStorage.getItem('user');
    const userInfo = JSON.parse(user || '{}');
    this.roleId = userInfo.roleId;
    this.userId = userInfo.id;
    this.fetchProducts();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
    console.log('CheckedId', this.setOfCheckedId);
  }

  onAllChecked(value: boolean): void {
    this.ProductList.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly Product[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }


  refreshCheckedStatus(): void {
    console.log('Current Page Data:', this.listOfCurrentPageData);
    console.log('Checked Set:', this.setOfCheckedId);
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
    console.log('Checked:', this.checked, 'Indeterminate:', this.indeterminate);
}



  viewProductDetail(id?): void {
    if (id) {
      this.router.navigate(['products/detail', id]);
      this.userService.addUserActivity('product', id, 'view');
    } else if (this.setOfCheckedId.size === 1) {
      const id = Array.from(this.setOfCheckedId)[0];
      this.router.navigate(['products/detail', id]);
      this.userService.addUserActivity('product', id, 'view');
    }
  }

  editProduct(id?): void {
    if (id) {
      this.router.navigate(['products/update', id]);
    } else if (this.setOfCheckedId.size === 1) {
      const id = Array.from(this.setOfCheckedId)[0];
      this.router.navigate(['products/update', id]);
    }
  }

  confirmDelete(): void {
    this.isDeleteModalVisible = true;
  if (this.setOfCheckedId.size === 1) {
    const id = Array.from(this.setOfCheckedId)[0];
    this.selectedProduct = this.ProductList.find(product => product.id === id);
    if (this.selectedProduct) {
      this.disableProduct(this.selectedProduct);
    }
   }
  }

  confirmBatchDelete(): void {
    console.log('Reset button clicked');
    console.log('Checked IDs:', Array.from(this.setOfCheckedId));
    this.isBatchDeleteModalVisible = true;
    if (this.setOfCheckedId.size > 1) {
      this.isBatchDeleteModalVisible = true;
      const ids = Array.from(this.setOfCheckedId);
      console.log('Selected IDs:', ids);
    } else {
      this.message.error('Please select more than one user to batch delete.');
    }
  }

  disableProduct(product: any) {
    console.log('Disable user:', product);
    this.disableForm.patchValue(
      {
        status:product.status,
        id: product.id,
        updated_by: this.userId,
      }
    );
    console.log('Form data after patch:', this.disableForm.value)
  }

  handleCancel(): void {
    this.isDeleteModalVisible = false;
    this.isBatchDeleteModalVisible = false;
  }



  handleOk(): void {
      this.submitDisableForm();
      this.isDeleteModalVisible = false;
    }


  handleBatchOk(): void {
      this.submitBatchDisableForm();
      this.isBatchDeleteModalVisible = false;
    }

  submitDisableForm(){
      const updatedUser = this.disableForm.value;
      const updateUserDto = {
        id:updatedUser.id,
        status: 'deleted',
        updated_by: this.userId,
      };
      console.log('Form data being submitted:', updateUserDto);

      this.webService.editProduct(updatedUser.id, updateUserDto).then(
        response => {
          this.message.success('Delete successfully');
          this.reload();
        }
      ).catch(
        error => {
          this.message.error('Failed to change user status');
        }
      );
  }


  submitBatchDisableForm(): void {
    const userIds = Array.from(this.setOfCheckedId);
    const userDtos = userIds.map(id => this.ProductListFiltered.find(product => product.id === id));

    Promise.all(userDtos.map(userDto => {
      if (userDto) {
        this.disableProduct(userDto);
        return this.submitDisableForm();
      }
    })).then(() => {
      this.message.success('Delete successfully');
      this.reload();
    }).catch(error => {
      this.message.error('Failed to change user statuses');
    });
  }

  changeSignalCheck(e: boolean, item: RowSettings): void {
    item.show = e;
  }

  dropTableConfig(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.Product_rows, event.previousIndex, event.currentIndex);
  }


  setFilterOptions() {
    this.productNames.clear();
    this.statuses.clear();
    this.barcodes.clear();
    this.brands.clear();
    this.countries.clear();
    this.brandsList.clear();
    this.first_level.clear();
    this.second_level.clear();
    this.third_level.clear();

    this.ProductList.forEach((product: Product) => {
      const productNameMatch = this.searchParam.product_name === null || this.searchParam.product_name === '' || (product.name && product.name.toLowerCase().includes(this.searchParam.product_name.toLowerCase()));
      const statusMatch = this.searchParam.status === null || this.searchParam.status === '' || product.status === this.searchParam.status;
      const barcodeMatch = this.searchParam.barcode === null || this.searchParam.barcode === '' || (product.barcode && product.barcode.includes(this.searchParam.barcode));
      const countryMatch = this.searchParam.country === null || this.searchParam.country.length === 0 || this.searchParam.country.includes(product.country_name.toString());
      const brandListMatch = this.searchParam.brand_list === null || this.searchParam.brand_list.length === 0 || this.searchParam.brand_list.includes(product.brand_name.toString());
      const firstLevelNameMatch = this.searchParam.first_level_name === null || this.searchParam.first_level_name.length === 0 || this.searchParam.first_level_name.includes(product.category_id.first_level_name);
      const secondLevelNameMatch = this.searchParam.second_level_name === null || this.searchParam.second_level_name.length === 0 || this.searchParam.second_level_name.includes(product.category_id.second_level_name);
      const thirdLevelNameMatch = this.searchParam.third_level_name === null || this.searchParam.third_level_name.length === 0 || this.searchParam.third_level_name.includes(product.category_id.third_level_name);
      const allMatch = productNameMatch && statusMatch && barcodeMatch && countryMatch && brandListMatch && firstLevelNameMatch && secondLevelNameMatch && thirdLevelNameMatch;
      if (allMatch) {
        this.productNames.add(product.name);
        this.statuses.add(product.status);
        this.barcodes.add(product.barcode);
      }
      if (!(productNameMatch && statusMatch && barcodeMatch))
        return;
      if (brandListMatch && firstLevelNameMatch && secondLevelNameMatch && thirdLevelNameMatch)
        this.countries.add(product.country_name);
      if (countryMatch && firstLevelNameMatch && secondLevelNameMatch && thirdLevelNameMatch)
        this.brandsList.add(product.brand_name);
      if (countryMatch && brandListMatch && secondLevelNameMatch && thirdLevelNameMatch)
        this.first_level.add(product.category_id.first_level_name);
      if (countryMatch && brandListMatch && firstLevelNameMatch && thirdLevelNameMatch)
        this.second_level.add(product.category_id.second_level_name);
      if (countryMatch && brandListMatch && firstLevelNameMatch && secondLevelNameMatch)
        this.third_level.add(product.category_id.third_level_name);
    });
    this.productNameFilterOptions = Array.from(this.productNames);
    this.statusFilterOptions = Array.from(this.statuses);
    this.barcodesFilterOptions = Array.from(this.barcodes);
    this.brandFilterOptions = Array.from(this.brands);
    this.countryFilterOptions = Array.from(this.countries);
    this.brandListFilterOptions = Array.from(this.brandsList);
    this.firstLevelFilterOptions = Array.from(this.first_level);
    this.secondLevelFilterOptions = Array.from(this.second_level);
    this.thirdLevelFilterOptions = Array.from(this.third_level);
  }

  isNotEmpty(value: any): boolean {
    return value !== null && value !== undefined && value !== '';
  }

  fetchProducts() {
    this.isSpinning = true;
    this.cacheService.allProducts().then((products) => {
      this.ProductList = products.filter((product: any) => product.status !== 'deleted') ;
      this.ProductListFiltered = Array.from(this.ProductList);
      this.setFilterOptions();
      this.isSpinning = false;
    });
  }

  reload() {
    this.isSpinning = true;
    this.cacheService.allProducts(true).then((products) => {
      this.ProductList = products.filter((product: any) => product.status !== 'deleted') ;
      this.ProductListFiltered = Array.from(this.ProductList);
      this.setFilterOptions();
      this.isSpinning = false;
    });
  }

  search() {
    this.ProductListFiltered = this.ProductList.filter(product => {
      const productNameMatch = this.searchParam.product_name === null || this.searchParam.product_name === '' || (product.name && product.name.toLowerCase().includes(this.searchParam.product_name.toLowerCase()));
      // const statusMatch = this.searchParam.status === null || this.searchParam.status === '' || product.status === this.searchParam.status;
      const barcodeMatch = this.searchParam.barcode === null || this.searchParam.barcode === '' || (product.barcode && product.barcode.includes(this.searchParam.barcode));
      const countryMatch = this.searchParam.country === null || this.searchParam.country.length === 0 || (product.country_name && this.searchParam.country.includes(product.country_name.toString()));
      const brandListMatch = this.searchParam.brand_list === null || this.searchParam.brand_list.length === 0 || (product.brand_name && this.searchParam.brand_list.includes(product.brand_name.toString()));
      const firstLevelNameMatch = this.searchParam.first_level_name === null || this.searchParam.first_level_name.length === 0 || (product.category_id && this.searchParam.first_level_name.includes(product.category_id.first_level_name));
      const secondLevelNameMatch = this.searchParam.second_level_name === null || this.searchParam.second_level_name.length === 0 || (product.category_id && this.searchParam.second_level_name.includes(product.category_id.second_level_name));
      const thirdLevelNameMatch = this.searchParam.third_level_name === null || this.searchParam.third_level_name.length === 0 || (product.category_id && this.searchParam.third_level_name.includes(product.category_id.third_level_name));
      // return productNameMatch && statusMatch && barcodeMatch && countryMatch && brandListMatch && firstLevelNameMatch && secondLevelNameMatch && thirdLevelNameMatch;
      return productNameMatch  && barcodeMatch && countryMatch && brandListMatch && firstLevelNameMatch && secondLevelNameMatch && thirdLevelNameMatch;

    });
  }

  onChangeSearchParams(value: string): void {
    this.search();
    this.setFilterOptions();
  }

  setTableSize(size: NzTableSize): void {
    this.tableSize = size;
    this.TABLE_SIZE_OPTIONS.forEach(option => option.selected = option.value === size);
  }
}